import * as React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { IZonesVariables, ZonesDoc } from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import { useQueryParams } from '~/src/lib/useQueryParams'
import { useQueryTtl } from '~/src/lib/useQueryTtl'
import SoundZoneRow from '~/src/components/SoundZoneRow'
import { ZoneStatus, ZoneFilters, generateFilters } from '~/src/components/ZoneFilters'

export default function Zones() {
  const navigate = useNavigate()
  const location = useLocation()
  const routeParams = useParams()
  const queryParams = useQueryParams(location)
  const variables: IZonesVariables = {
    account: routeParams.account!,
    first: 20,
    filters: generateFilters({
      query: queryParams.get('q'),
      status: queryParams.get('s') as ZoneStatus,
    }),
  }

  const query = useQueryTtl(ZonesDoc, {
    variables,
    ttlId: 'zones',
    ttl: '60s',
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
  })

  const isFetching = query.networkStatus === 3
  const loadMore = () => query.fetchMore({
    variables: {
      ...query.variables,
      cursor: soundZones.pageInfo.endCursor,
      first: 100,
    },
  })

  const soundZones = assertData(query, 'account.soundZones')
  const byLocation = React.useMemo(() => {
    return soundZones?.edges.reduce((o, { node }) => {
      const location = node.location?.name || 'unknown'
      o[location] ||= []
      o[location].push(node)
      return o
    }, {}) || {}
  }, [soundZones])

  const updateParams = (
    paramName: string | null,
    paramValue?: string
  ) => {
    let params = ''
    if (paramName != null) {
      // Mutates the object, which is fine since we're recreating it on the next render
      queryParams.set(paramName === 'status' ? 's' : 'q', paramValue)
      params = queryParams.toString()
    }
    return navigate('?' + params, { replace: true })
  }

  return <div className="Zones">
    <section>
      <ZoneFilters
        query={queryParams.get('q')}
        status={(queryParams.get('s') || '') as ZoneStatus}
        onUpdate={updateParams}
      />
      <Link
        to="create"
        children="Add zone"
      />
    </section>

    {!soundZones && (
      <section>
        <h4 className="placeholder-text" />
        <h2 className="placeholder-text" />
      </section>
    )}

    {Object.keys(byLocation).map((locationName) => (
      <section key={locationName}>
        <h4>{locationName}</h4>
        {byLocation[locationName].map(node => (
          <SoundZoneRow
            key={node.id}
            soundZone={node}
            linkTo={`/account/${query.data.account.id}/zones/${node.id}`}
          />
        ))}
      </section>
    ))}

    {soundZones?.pageInfo.hasNextPage && (
      <button
        type="button"
        onClick={loadMore}
        disabled={isFetching}
        className="Zones__load-more"
        children="Load more"
      />
    )}
  </div>
}


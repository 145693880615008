import * as React from 'react'
import { ElementProps } from '../lib/dom'
import useVirtualScrollParent from '../lib/useVirtualScrollParent'

export type VirtualizedListProps<ItemType = unknown> = {
  items: ItemType[]
  itemComponent?: React.ComponentType<{
    item: ItemType
    style: any
    containerRef?: React.Ref<HTMLElement>
  }>
  estimateSize?: number | ((index: number) => number)
  overscan?: number
  containerProps?: ElementProps
}

export function VirtualizedList<ItemType>({
  items,
  estimateSize,
  overscan = 10,
  containerProps = {},
  itemComponent: ItemComponent,
}: VirtualizedListProps<ItemType>) {
  const parentRef = React.useRef<HTMLElement>()
  const virtualizer = useVirtualScrollParent({
    size: items.length,
    estimateSize,
    overscan,
    parentRef,
  })

  return (
    <ul
      ref={parentRef}
      style={{
        position: 'relative',
        height: virtualizer.totalSize + 'px',
      }}
      {...containerProps}
    >
      {virtualizer.virtualItems.map((row, index) => {
        return <ItemComponent
          key={row.key}
          containerRef={index === 0 ? virtualizer.estimateSizeRef : undefined}
          item={items[row.index]}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            // Avoid setting height to allow negative margins to be applied correctly
            // height: `${row.size}px`,
            transform: `translateY(${row.start}px)`,
          }}
        />
      })}
    </ul>
  )
}

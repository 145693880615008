import * as React from 'react'
// eslint-disable-line import/named
import { NormalizedCacheObject } from '@apollo/client'
import { CachePersistor } from 'apollo3-cache-persist'

export const CachePersistorContext = React.createContext<CachePersistor<NormalizedCacheObject> | null>(null)

export const CachePersistorProvider = CachePersistorContext.Provider

export function useCachePersistor() {
  return React.useContext(CachePersistorContext)
}


import * as React from 'react'
import { Link } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import { NowPlayingSubscriptionDoc, INowPlaying_Playing } from '~/src/graphql'
import * as displayable from '~/src/lib/displayable'
import { formatDate } from '../lib/date'

export default function NowPlaying({
  soundZoneId,
  nowPlaying,
  style = 'startedAt',
}: {
  soundZoneId: string,
  nowPlaying?: INowPlaying_Playing
  style?: 'compact' | 'startedAt' | 'cover'
}) {
  const subscription = useSubscription(NowPlayingSubscriptionDoc, {
    variables: { input: { soundZone: soundZoneId } },
  })

  // `nowPlayingUpdate` can land with `nowPlaying: null`
  const latest = subscription.data?.nowPlayingUpdate
    ? subscription.data.nowPlayingUpdate?.nowPlaying
    : nowPlaying

  if (!latest?.track) {
    return null
  }

  const title = <>
    {displayable.artists(latest.track.artists, { link: true })}
    {' - '}
    {latest.track.album
      ? <Link
        to={displayable.link(latest.track)}
        title="Go to album"
        children={latest.track.name}
      />
      : latest.track.name
    }
  </>

  if (style === 'compact') {
    return title
  }

  return <div className="NowPlaying">
    <h4 className="NowPlaying__title">🎵 {title}</h4>
    <p
      className="NowPlaying__started-at"
      children={formatDate(latest.startedAt)}
    />
    {style === 'cover' && (
      <img
        className="NowPlaying__cover"
        src={latest.track.album.image.url}
        alt=""
      />
    )}
  </div>
}

import { ApolloLink, fromPromise } from '@apollo/client'

interface AuthLinkOptions {
  /** Resolves to the auth header value to inject */
  headerValue: () => Promise<string | null | undefined>
  /** Name of the header */
  headerName?: string
}

/**
 * Responsible for injecting authentication (determined by `headerName`)
 * header into Apollo client requests.
 *
 * Defaults to injecting the token returned by `headerValue()` (unless the value is falsey),
 * but this can be controlled via the optional `auth` query context value:
 * - `true` will require a token to be returned, if empty the request will be skipped
 * - `false` will result in the token always being omitted from the request
 */
export function authLink(userConfig: AuthLinkOptions) {
  const config: Required<AuthLinkOptions> = Object.assign({
    headerName: 'Authorization',
  }, userConfig)

  return new ApolloLink((operation, forward) => {
    const context = operation.getContext()

    if (context.auth === false) {
      return forward(operation)
    }

    return fromPromise(config.headerValue()).flatMap(token => {
      if (!token && context.auth === true) {
        // TODO: Remove debug logging
        return null
      }
      if (token) {
        const headers = context.headers || {}
        headers[config.headerName] = token
        operation.setContext({ headers })
      }
      return forward(operation)
    })
  })
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shallowClone = void 0;
function shallowClone(obj, createIfEmpty, assumeArray) {
    if (createIfEmpty === void 0) { createIfEmpty = false; }
    if (assumeArray === void 0) { assumeArray = false; }
    if (obj == null) {
        if (createIfEmpty) {
            return (assumeArray ? [] : {});
        }
        return obj;
    }
    else if (Array.isArray(obj)) {
        return obj.slice();
    }
    return Object.assign({}, obj);
}
exports.shallowClone = shallowClone;

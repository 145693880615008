import * as React from 'react'
import { Link } from 'react-router-dom'
import { useSubscription } from '@apollo/client'
import { SoundZoneRowDoc, ISoundZoneRow_SoundZone } from '~/src/graphql'
import NowPlaying from './NowPlaying'
import StatusIndicator from './StatusIndicator'

export default function SoundZoneRow({
  soundZone,
  linkTo = null,
}: {
  soundZone: ISoundZoneRow_SoundZone,
  linkTo?: string,
}) {
  useSubscription(SoundZoneRowDoc, {
    variables: { input: { soundZone: soundZone.id } },
  })

  const incomplete = !soundZone.subscription || !soundZone.errors
  let state = 'unknown'
  // FIXME: Missing soundZone.status.message (!= NOT_SETUP)
  if (incomplete) {
    // ignore
  } else if (!soundZone.isPaired || !soundZone.subscription.isActive) {
    state = 'warning'
  } else if (soundZone.errors.edges.length) {
    state = 'error'
  } else {
    state = 'ok'
  }

  const source = soundZone.playFrom ? <>
    (<Link
      to={`/source/${soundZone.playFrom.id}`}
      children={soundZone.playFrom.name}
    />)
  </> : null

  return <div className={`toolbar toolbar--baseline SoundZoneRow SoundZoneRow--${state}`}>
    <h2>
      <StatusIndicator state={state} />
      {' '}
      {linkTo ? <Link to={linkTo} children={soundZone.name} /> : soundZone.name}
    </h2>
    <div className="SoundZoneRow__status">
      {!soundZone.subscription || !soundZone.errors ? (
        'Loading...'
      ) : !soundZone.subscription.isActive ? (
        'Inactive subscription'
      ) : !soundZone.isPaired ? (
        'No player paired'
      ) : soundZone.errors.edges.length ? (
        soundZone.errors.edges[0].node.cause
      ) : (
        soundZone.playback?.state === 'paused' ? (
          <>Paused {source}</>
        ) : (
          <>
            {'🎵 '}
            <NowPlaying
              nowPlaying={soundZone.nowPlaying}
              soundZoneId={soundZone.id}
              style="compact"
            />
            {' '}
            {source}
          </>
        )
      )}
    </div>
  </div>
}

import type { OperationDefinitionNode } from 'graphql'
import * as Apollo from '@apollo/client'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import gqlIntrospection from '~/src/graphql'
import { AuthManager } from '~/src/lib/auth'
import { formatNumber } from '~/src/lib/number'
import { link as networkStatusLink } from '~/src/lib/useNetworkStatus'
import { WebSocketLink } from './WebSocketLink'
import { authLink } from './authLink'
import { fetchAllLink } from './fetchAllLink'
import { typePolicies } from './typePolicies'
import type { TypedQueryContext } from './typed-query-context'
import { resetQueryTtl } from './useQueryTtl'

export { useQueryTtl } from './useQueryTtl'

// Re-export useQuery() with strongly typed `context`
export const useQuery: <TData = any, TVariables = Apollo.OperationVariables>(
  query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.QueryHookOptions<TData, TVariables> & TypedQueryContext
) => Apollo.QueryResult<TData, TVariables> = Apollo.useQuery


export const httpEndpoint = 'https://api.soundtrackyourbrand.com/v2'
export const wsEndpoint = 'wss://api.soundtrackyourbrand.com/v2/graphql-transport-ws'

const VERSION = '0.2'
const VERSION_KEY = 'syb.cacheVersion'
const CACHE_KEY = 'syb.cache'

export const cache = new InMemoryCache({
  possibleTypes: gqlIntrospection.possibleTypes,
  typePolicies,
  // Don't default to normalize using `id` - it's better to be explicit
  dataIdFromObject: false as any,
})

// Run garbage collection before persisting cache
/*
FIXME: cache.gc() currently seems to incorrectly remove entities that are referenced in paginated fields.
This can be seen by going to select account -> pick account -> pick location ->
wait for GC to happen. Often times GC will remove accounts/locations/zones that
should be kept.

const originalExtract = cache.extract
cache.extract = (...args) => {
  const removed = cache.gc()
  if (removed.length > 0) {
    console.debug(`[apollo] Garbage collected ${removed.length} records before persisting`)
    console.debug(removed)
  }
  return originalExtract.apply(cache, args)
}
*/


const storage = new LocalStorageWrapper(window.localStorage)

/** apollo3-cache-persist wrapper of the InMemoryCache instance. */
export const persistor = new CachePersistor<NormalizedCacheObject>({
  cache,
  storage,
  key: CACHE_KEY,
  trigger: 'write',
  debounce: 5e3,
  maxSize: 4 * 1024 * 1024,
  debug: false,
  persistenceMapper: async (data) => {
    // Set version key in addition to updating data
    await storage.setItem(VERSION_KEY, VERSION)
    console.debug(`[apollo] Persisting ${formatNumber(data.length, '0,000 b')} of serialized cache`)
    return data
  }
})


/**
 * Asynchronously restores a persisted cache into memory.
 * Resets the cache if the app version is different from the stored version.
 */
export async function restoreApolloCache() {
  const storedVersion = await storage.getItem(VERSION_KEY)
  return storedVersion === VERSION
    ? persistor.restore()
    : persistor.purge()
}


// TODO: Move elsewhere
export const authManager = new AuthManager({
  tokenKey: 'syb.auth',
})

/**
 * Apollo client instance - global across the app.
 */
export const apollo = new Apollo.ApolloClient({
  cache,
  link: Apollo.ApolloLink.from([
    fetchAllLink,
    // TODO: This should probably be part of the `AuthManager` class,
    // since they are coupled with regards to `context: { auth: boolean }`.
    authLink({
      headerValue: () => getToken(true),
    }),
    new Apollo.ApolloLink((operation, forward) => {
      const context = operation.getContext()
      const headers = context.headers || {}

      headers['X-User-Agent'] = JSON.stringify({
        platform: 'graphql-pilot',
        platformVersion: VERSION,
        locale: 'EN',
        country: 'US',
        user: authManager.getViewer(),
        account: authManager.getAccount(),
      })
      operation.setContext({ headers })

      /*
        Manages `Authorization` HTTP header based off the presence (and value)
        of the optional `auth` query context value:
        - `true` will require a token to be returned, if no token exists the
          request will be skipped
        - `false` will result in the token always being omitted from the request
      */
      if (context.auth === false) {
        return forward(operation)
      }
      // @ts-ignore wrong type for `token`
      return Apollo.fromPromise(getToken(true)).flatMap(token => {
        if (!token && context.auth === true) {
          // TODO: Remove debug logging
          return null
        }
        if (token) {
          const headers = context.headers || {}
          headers['Authorization'] = token
          operation.setContext({ headers })
        }
        return forward(operation)
      })
    }),

    // Request error handling
    onError((ctx) => {
      if (ctx.graphQLErrors) {
        for (let i = 0; i < ctx.graphQLErrors.length; i++) {
          const error = ctx.graphQLErrors[i]
          const { message, path } = error
          const location = [ctx.operation.operationName || '_'].concat(path as string[])
          Object.assign(error, { graphQLStack: location })
          console.warn(
            `[apollo] GraphQL error: ${message.trim()} @ ${location.join('.')}`,
          )
        }
      }
      if (ctx.networkError) {
        console.error(
          '[apollo] Network error:',
          ctx.networkError,
          ctx.networkError.stack ? '\n' + ctx.networkError.stack : '',
        )
        if (ctx.networkError['statusCode'] === 401) {
          authManager.logout()
        }
      }
    }),

    // Forward subscriptions to WebSocketLink
    Apollo.split(
      ({ query, operationName, variables }) => {
        const def = getMainDefinition(query)
        // console.debug(`[apollo] ${def.operation} ${operationName}`)
        // console.log(variables)
        return def.operation === 'subscription'
      },
      // TODO: Trigger wsLink.reconnect(token) on token change
      new WebSocketLink({
        url: wsEndpoint,
        connectionParams: () => getToken(true).then(token => {
          // Header name must be capitalized
          return { Authorization: token }
        })
      }),
      networkStatusLink.concat(new Apollo.HttpLink({
        uri: httpEndpoint,
        credentials: 'omit',
      })),
    ),
  ]),
})

apollo.onResetStore(async () => {
  resetQueryTtl()
  await apollo?.clearStore()
  if (persistor) {
    await persistor.purge()
  }
  // resetStore() doesn't seem to automatically clear the cache map if only
  // called once before/after purge()
  await apollo?.clearStore()
})

authManager.gqlClient = apollo

function getToken(asAuthHeader = false): Promise<string | undefined> {
  return authManager.token().then(token => {
    if (token && asAuthHeader) {
      return 'Bearer ' + token
    }
    return token || undefined
  })
}

/**
 * Returns the first operation definition in the provided GraphQL query AST, if
 * any. Similar to `getMainDefinition` from `@apollo/client`/`graphql` except
 * that it doesn't validate the document or generate an temporary array.
 */
export function getMainDefinition(doc: Apollo.DocumentNode) {
  const definition = (
    doc?.definitions.find(d => d.kind === 'OperationDefinition' && d.operation)
  ) as OperationDefinitionNode
  if (!definition) {
    throw new Error(`Expected a parsed GraphQL query with a query/mutation/subscription`)
  }
  return definition
}

import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { AlbumRouteDoc } from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import * as displayable from '~/src/lib/displayable'
import { cx } from '../lib/classNames'
import { LocationHashScroller } from '../components/LocationHashScroller'
import DisplayImage from '../components/DisplayImage'
import { TracklistTable } from '../components/Tracklist'

export default function Album() {
  const params = useParams()
  const variables = {
    id: displayable.expandId(params.id, 'album'),
    market: 'US' as any, // FIXME: Issue with enum validation
  }

  const query = useQuery(AlbumRouteDoc, {
    variables,
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
    // fetchPolicy: 'cache-and-network', // revalidate on mount if cached
    returnPartialData: true,
  })

  let album = assertData(query, 'album')

  // Guard against empty partial data
  if (!album?.__typename) { album = null }

  const display = album || displayable.skeletonOf('Album')
  const cxSkeleton = album ? undefined : 'placeholder-text'

  return <div key={display.id} className="Album">
    <LocationHashScroller pause={query.loading} />
    <section className="toolbar PageHeader">
      <DisplayImage item={display} size="normal" className="PageHeader__image" />
      <div className="PageHeader__meta">
        <h2
          className={cx('PageHeader__title', cxSkeleton)}
          children={display.title}
        />
        <p
          className={cxSkeleton}
          children={display.artists && displayable.subtitle(album, { link: true })}
        />
        <p
          className={cxSkeleton}
          children={album?.releaseDate && ('Released in ' + new Date(album.releaseDate.timestamp).getFullYear())}
        />
      </div>
    </section>
    {album?.tracks?.edges.length !== 0 && (
      <section id="tracks" className="Tracklist">
        <TracklistTable
          edges={album?.tracks?.edges}
          skeletonUpTo={album?.tracks ? 0 : 5}
          album={false}
          className="Album__tracks"
        />
      </section>
    )}
    {album?.copyrights?.length && (
      <ul className="flat Album__copyrights">
        {album.copyrights.map(({ text }, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>
    )}
  </div>
}

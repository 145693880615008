import {
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client/core'
import { print, GraphQLError } from 'graphql'
import { createClient, ClientOptions, Client } from 'graphql-ws'

/*
  Taken directly from
  https://github.com/enisdenjo/graphql-ws

  Uses graphql-vs instead of deprecated subscriptions-transport-ws,
  but currently fails to connect to the API.
*/
export class WebSocketLink extends ApolloLink {
  private client: Client

  constructor(options: ClientOptions) {
    let activeSocket, timedOut
    super()
    this.client = createClient({
      ...options,
      on: {
        connected: (socket) => (activeSocket = socket),
        ping: (received) => {
          if (!received) {
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN) {
                activeSocket.close(4408, 'Request Timeout')
              }
            }, 5e3)
          }
        },
        pong: (received) => {
          if (received) { clearTimeout(timedOut) }
        },
      }
    })
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err)
            }

            // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
            if (err.name === 'CloseEvent') {
              const error = err as { code: number, reason?: string }
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${error.code} ${error.reason || ''}`,
                ),
              )
            }

            return sink.error(
              new Error((err as GraphQLError[])
                .map(({ message }) => message)
                .join(', ')
              ),
            )
          },
        },
      )
    })
  }
}

import * as React from 'react'
import { Assign } from '../lib/types'

type AsyncButtonProps = Assign<React.ButtonHTMLAttributes<HTMLButtonElement>, {
  onError?: (error: Error) => void
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>
}>

export const AsyncButton = React.forwardRef<
  HTMLButtonElement,
  AsyncButtonProps
>(({ onError, onClick, ...props }, forwardedRef) => {
  const [busy, setBusy] = React.useState(false)

  return <button
    ref={forwardedRef}
    type="button"
    {...props}
    onClick={event => {
      if (busy || !onClick) { return }
      const result = onClick(event)
      if (result && typeof result.then === 'function') {
        setBusy(true)
        result.finally(() => setBusy(false))
      }
    }}
    disabled={busy || props['disabled']}
  />
})

AsyncButton.displayName = 'AsyncButton'

/*
 This module consists of DOM utils adapted from various sources:
 - https://github.com/soundtrackyourbrand/ui/blob/master/src/lib/dom.ts
 - https://github.com/soundtrackyourbrand/business/blob/master/app/lib/scroll.js
*/

const html = document.documentElement
const body = document.body

/**
 * Pseudo HTML element which behaves like a regular element in terms of scrolling.
 */
export const documentElement: Partial<Element> = {
  set scrollLeft(x) { html.scrollLeft = x },
  get scrollLeft() { return window.scrollX || window.pageXOffset },
  set scrollTop(x) { html.scrollTop = x },
  get scrollTop() { return window.scrollY || window.pageYOffset },
  get scrollWidth() {
    return Math.max(
      body.scrollWidth, html.scrollWidth,
      body.offsetWidth, html.offsetWidth,
      body.clientWidth, html.clientWidth,
    )
  },
  get scrollHeight() {
    return Math.max(
      body.scrollHeight, html.scrollHeight,
      body.offsetHeight, html.offsetHeight,
      body.clientHeight, html.clientHeight,
    )
  },
  get clientWidth() { return html.clientWidth },
  get clientHeight() { return html.clientHeight },
  getBoundingClientRect() {
    return {
      x: 0,
      y: 0,
      top: 0,
      left: 0,
      width: this.clientWidth,
      height: this.clientHeight,
    } as DOMRect
  },
  addEventListener(event, ...args) {
    const target = event === 'scroll' ? window : html
    // @ts-ignore
    return target.addEventListener(event, ...args)
  },
  removeEventListener(event, ...args) {
    const target = event === 'scroll' ? window : html
    // @ts-ignore
    return target.removeEventListener(event, ...args)
  },
}

type FindTarget = string | ((HTMLElement) => boolean)
type FindBoundary = FindTarget | HTMLElement | null

/**
 * Finds and returns the first parent node that satisfies `match`.
 *
 * Passing a string for `match` and/or `boundary` will match against class,
 * whereas functions will be called with each ancestor, stopping if the
 * function returns true.
 *
 * @param element - Starting element to traverse ancestor for
 * @param match - Ancestor to look for
 * @param boundary - Abort if found before reaching `match`
 * @return the matched element if found
 */
export function findParent(
  element: HTMLElement,
  match: FindTarget,
  boundary: FindBoundary = null,
): HTMLElement | null {
  if (typeof match === 'string') {
    const matchClass = match
    match = (node) => node?.classList?.contains(matchClass)
  }
  if (typeof match !== 'function') {
    throw new Error(`findParent: match must be a function or class string, got ${typeof match}`)
  }
  if (typeof boundary === 'string') {
    const boundaryClass = boundary
    boundary = (node) => node?.classList?.contains(boundaryClass)
  } else if (boundary && typeof boundary !== 'function') {
    const boundaryElement = boundary
    boundary = (node) => node === boundaryElement
  }

  let node: HTMLElement | null = element

  // @ts-ignore `boundary` is guaranteed to be callable if truthy
  // eslint-disable-next-line no-unmodified-loop-condition
  while (node && (!boundary || !boundary(node))) {
    if (match(node)) {
      return node
    }
    node = node.parentElement
  }

  return null
}

/**
 * Finds and returns the closest element that is scrollable, traversing upwards
 * the DOM hierarchy.
 *
 * @param element - Starting element to traverse ancestors for
 * @return The closest scrollable element
 */
export function findScrollParent(element: Element): Element {
  const scrollingElement = document.scrollingElement || document.documentElement
  if (!(element instanceof HTMLElement || element instanceof SVGElement)) {
    return scrollingElement
  }
  let style = getComputedStyle(element)
  if (style.position === 'fixed') {
    return scrollingElement
  }
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = /(auto|scroll|overlay)/
  let parent = element.parentElement
  while (parent) {
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent === document.body ? scrollingElement : parent
    }
    parent = parent.parentElement
  }
  return scrollingElement
}

/**
 * Finds and returns the first interactive parent node, unless boundary is
 * reached.
 *
 * @param element - Starting element to traverse ancestor for
 * @param boundary - Abort if found before reaching `match`
 * @return the closest interactive element if found
 */
export function findInteractiveParent(
  element: HTMLElement,
  boundary: FindBoundary = null,
) {
  return findParent(element, isInteractiveElement, boundary)
}

/**
 * Wrapper for HTMLElement.contains() which works with SVG children in IE.
 *
 * SVGElement.contains is not defined in IE, but .compareDocumentPosition() is.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
 * @param parent
 * @param child
 * @param trueWhenEqual - return true if parent & child are the same element
 * @return true if child is contained in parent
 */
export function containsElement(
  parent: HTMLElement,
  child: HTMLElement,
  trueWhenEqual = false
): boolean {
  if (!parent || !child) return false
  if (trueWhenEqual && parent === child) return true
  return typeof parent.contains === 'function'
    ? parent.contains(child)
    : !!(parent.compareDocumentPosition(child) & 16) // contains
}

export const FOCUSABLE_ELEMENTS = ['select', 'input', 'textarea', 'label']
export const INTERACTIVE_ELEMENTS = FOCUSABLE_ELEMENTS.concat([
  'a',
  'button',
  'details',
  'dialog',
  'form',
  'menu',
  'summary',
])

export function isElementType(element: HTMLElement, types: string[]): boolean {
  return types.includes(element.nodeName.toLowerCase())
}

export function isInteractiveElement(element: HTMLElement): boolean {
  return isElementType(element, INTERACTIVE_ELEMENTS)
}

export function isFocusableElement(element: HTMLElement): boolean {
  return isElementType(element, FOCUSABLE_ELEMENTS)
}

export function isFocusedElement(node: HTMLElement | null | undefined) {
  return !!(node && document.activeElement === node)
}

export type ElementProps<E extends Element = HTMLElement> =
  React.DetailedHTMLProps<React.HTMLAttributes<E>, E> & { ref?: any }

import * as React from 'react'
import {
  ApolloClient,
  gql,
  NormalizedCacheObject, // eslint-disable-line import/named
} from '@apollo/client'
import { ILoginUserInput, ILoginUserPayload, IRefreshLoginPayload } from '~/src/graphql'
import { formatDate } from './date'
import { parse as parseKey } from './keys'

export const AuthContext = React.createContext<AuthManager | null>(null)

export const AuthProvider = AuthContext.Provider

export function useAuth(params?: { throwWhenMissing: true }): AuthManager
export function useAuth(params: { throwWhenMissing: false }): AuthManager | null

export function useAuth({ throwWhenMissing = false } = {}) {
  const auth = React.useContext(AuthContext)
  const triggerUpdate = React.useReducer(x => x + 1, 0)[1]

  // Re-render on auth changes
  React.useEffect(() => {
    return auth?.subscribe((event) => { triggerUpdate() })
  }, [auth, triggerUpdate])

  if (!auth && throwWhenMissing) {
    throw new Error(`useAuth() requires <AuthProvider>`)
  }

  return auth
}

type AuthData = {
  token?: string
  refreshToken?: string
  expiresAt?: number
  activeViewerId?: string
  activeAccountId?: string
}

type AuthEvent = 'login' | 'logout' | 'loaded' | 'refreshed' | 'setAccount'
type AuthSubscriptionCallback = (event: AuthEvent, auth: AuthManager) => void

export class AuthManager {
  debug = false
  dataKey = 'syb.auth'
  data?: AuthData
  gqlClient?: ApolloClient<NormalizedCacheObject>
  tokenRefreshTimeout = 60 * 60e3 // 1 hour
  tokenPromise?: Promise<string | undefined>
  subscriptionCallbacks: AuthSubscriptionCallback[] = []

  constructor(options: {
    gqlClient?: ApolloClient<NormalizedCacheObject>,
    tokenRefreshTimeout?: number,
    tokenKey?: string
    debug?: boolean
  }) {
    Object.assign(this, options)
  }

  loadData = async (): Promise<AuthData> => {
    // TODO: Async in React Native
    const json = await localStorage.getItem(this.dataKey) || '{}'
    let d: AuthData = {}
    try {
      d = JSON.parse(json) || {}
    } catch (error) {
      this.debug && console.warn('[auth] Unable to read session data, resetting')
    }

    const valid = d.token && d.refreshToken && d.expiresAt && d.expiresAt > Date.now()
    this.data = {
      token: valid ? d.token : undefined,
      refreshToken: valid ? d.refreshToken : undefined,
      expiresAt: valid ? d.expiresAt : 0,
      activeViewerId: valid ? d.activeViewerId : undefined,
      activeAccountId: valid ? d.activeAccountId : undefined,
    }
    this.debug && console.info('[auth] token loaded data from storage', this.data)
    this.notify('loaded')
    return this.data
  }

  /** Updates the stored data. */
  updateData = async (data: Partial<AuthData> | undefined): Promise<AuthData> => {
    if (data && this.data) {
      data = Object.assign({}, this.data, data)
    }
    this.data = data
    await localStorage.setItem(this.dataKey, JSON.stringify(this.data))
    this.debug && console.info(data?.token
      ? `[auth] Updating auth data, expires ${formatDate(new Date(data.expiresAt!))}`
      : '[auth] Deleting stored auth data'
    )
    return this.data!
  }

  /** Returns true/false if there is a logged in user with a valid token. */
  loggedIn = (): boolean => {
    if (!this.data?.token) {
      return false
    }

    if (this.data?.expiresAt && this.data.expiresAt < Date.now()) {
      this.debug && console.log(
        `[auth] Session expired at ${formatDate(new Date(this.data.expiresAt))}`
      )
      return false
    }

    return true
  }

  /** Returns a promise that resolves with a valid token. */
  token = (): Promise<string | undefined> => {
    if (!this.gqlClient) {
      throw new Error('auth: gqlClient must be initialized before requesting token()')
    }

    /*
    if (expiresAt < now) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('[auth] Session expired on ' + new Date(expiresAt) + ', rejecting token promise')
      }
      return Promise.resolve(null)
    }
    */
    if (this.tokenPromise) {
      return this.tokenPromise
    }

    if (!this.data) {
      // Data hasn't been loaded from storage yet, trigger and retry afterwards
      return this.tokenPromise = this.loadData().then(() => {
        this.tokenPromise = undefined
        this.debug && console.log('[auth] refetching token after data has been loaded')
        return this.token()
      }).catch(err => {
        this.tokenPromise = undefined
        throw err
      })
    }

    const { token, expiresAt, refreshToken } = this.data
    const now = Date.now()

    if (expiresAt && now < expiresAt - this.tokenRefreshTimeout) {
      this.debug && console.log('[auth] returning still valid token')
      return Promise.resolve(token)
    }

    if (!refreshToken) {
      this.debug && console.log('[auth] no token or refreshToken available')
      return Promise.resolve(undefined)
    }

    const promise = this.tokenPromise = this.gqlClient.mutate<{
      refreshLogin: IRefreshLoginPayload
    }>({
      mutation: gql`
        mutation RefreshLogin($input: RefreshLoginInput!) {
          refreshLogin(input: $input) {
            token refreshToken expiresAt
          }
        }
      `,
      variables: {
        input: { refreshToken },
      },
      context: { auth: false },
    }).then(res => {
      if (!res.data) {
        throw new Error('auth: Refresh token response contains no data')
      }
      const { token, expiresAt } = res.data.refreshLogin
      return this.updateData({
        token,
        expiresAt: new Date(expiresAt).getTime(),
      }).then(() => {
        this.debug && console.log('[auth] returning token after refresh')
        this.notify('refreshed')
        return token
      })
    }).finally(() => {
      if (promise === this.tokenPromise) {
        this.tokenPromise = undefined
      }
    })

    return this.tokenPromise
  }

  /**
   * Authenticates using the given credentials and returns a promise that
   * resolves with a valid token.
   */
  login = (email: string, password: string): Promise<string> => {
    if (!this.gqlClient) {
      throw new Error('auth: gqlClient must be initialized before calling login()')
    }

    if (email.indexOf('@') < 0) {
      email += '@soundtrackyourbrand.com'
    }

    return this.gqlClient.mutate<{
      loginUser: ILoginUserPayload
    }, {
      input: ILoginUserInput
    }>({
      mutation: gql`
        mutation LoginUser($input: LoginUserInput!) {
          loginUser(input: $input) {
            userId token refreshToken expiresAt
          }
        }
      `,
      variables: {
        input: { email, password },
      },
      context: { auth: false },
    }).then(res => {
      if (!res.data) {
        throw new Error('auth: Login response contains no data')
      }
      const { userId, token, refreshToken, expiresAt } = res.data.loginUser
      return this.updateData({
        token,
        expiresAt: new Date(expiresAt).getTime(),
        refreshToken,
        activeViewerId: userId,
      }).then(() => {
        this.notify('login')
        return token
      })
    })
  }

  /** Clears all authentication data. */
  logout = (): Promise<AuthData> => {
    return this.updateData(undefined).then(result => {
      this.notify('logout')
      return result
    })
  }

  /** Changes the active account id */
  setAccount = (activeAccountId: string): void => {
    if (this.data && this.data.activeAccountId !== activeAccountId) {
      this.updateData({ activeAccountId })
      this.notify('setAccount')
    }
  }

  /** Returns the active account id */
  getAccount = (): string | null => {
    return this.data?.activeAccountId || null
  }

  /** Returns the active viewer id */
  getViewer = (): string | null => {
    return this.data?.activeViewerId || null
  }

  /** Returns the active viewer type (User | Device) */
  getViewerType = (): string | null => {
    const id = this.data?.activeViewerId
    if (!id) { return null }
    return parseKey(id)?.kind || null
  }

  /**
   * Subscribes to logged in status changes.
   * Returns a function that removes the subscription when called.
   */
  subscribe = (callback: AuthSubscriptionCallback) => {
    this.subscriptionCallbacks.push(callback)
    return () => {
      const index = this.subscriptionCallbacks.indexOf(callback)
      index >= 0 && this.subscriptionCallbacks.splice(index, 1)
    }
  }

  /** Notify all subscriptions */
  notify = (event: AuthEvent) => {
    this.subscriptionCallbacks.forEach(cb => {
      cb(event, this)
    })
  }
}

import * as React from 'react'
import { useApolloClient } from '@apollo/client'
import { AuthManager } from './auth'

AuthCacheManager.displayName = 'AuthCacheManager'

/** Resets Apollo & useHTTP cache on log out */
function AuthCacheManager({ auth }: {
  auth: AuthManager
}) {
  const apollo = useApolloClient()

  // Re-render on auth changes
  React.useEffect(() => {
    return auth?.subscribe((event) => {
      if (event === 'login' || event === 'logout') {
        setTimeout(() => {
          apollo.resetStore()
          if (process.env.NODE_ENV !== 'production') {
            console.log('[auth] Cleared Apollo cache due to log in/out')
          }
        }, 0)
      }
    })
  }, [auth, apollo])

  return null
}

export default React.memo(AuthCacheManager)

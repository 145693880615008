import * as React from 'react'

let _resizeObserver: ReturnType<typeof createResizeObserver>

/**
 * React hook that fires a callback whenever ResizeObserver detects a change to its size
 *
 * @see https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer
 * @param target - A React ref created by `useRef()` or an HTML element
 * @param callback - Invoked with a single `ResizeObserverEntry` any time
 *        the `target` resizes
 */
export function useResizeObserver<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  callback: (entry: ResizeObserverEntry, observer: ResizeObserver) => void
) {
  const lastCallback = React.useRef(callback)

  React.useLayoutEffect(() => {
    const element = target && 'current' in target ? target.current : target
    if (!element) { return }

    function cb(entries: ResizeObserverEntry[], observer: ResizeObserver) {
      lastCallback.current(entries[0], observer)
    }

    const observer = getResizeObserver()
    observer.subscribe(element as HTMLElement, cb)
    return () => {
      observer.unsubscribe(element as HTMLElement, cb)
    }
  }, [target])

  React.useEffect(() => {
    lastCallback.current = callback
  }, [callback])
}

export function getResizeObserver() {
  return _resizeObserver ||= createResizeObserver()
}

/**
 * ResizeObserver singleton.
 * Based off @react-hook/resize-observer.
 * Using a single observer is supposedly much more performant:
 * https://groups.google.com/a/chromium.org/forum/#!msg/blink-dev/z6ienONUb5A/F5-VcUZtBAAJ
 *
 * @see https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer
 */
function createResizeObserver() {
  const callbacks = new Map<unknown, ResizeObserverCallback[]>()
  const pendingEntries: ResizeObserverEntry[] = []

  const observer = new ResizeObserver((entries, observer) => {
    const hasPending = pendingEntries.length > 0
    pendingEntries.push(...entries)
    if (!hasPending) {
      window.requestAnimationFrame(() => {
        // Only trigger callback for the most recent update of each tracked element
        const updatedEntries = new Set<unknown>()
        for (let p = pendingEntries.length - 1; p >= 0; p--) {
          const entry = pendingEntries[p]
          if (!updatedEntries.has(entry)) {
            updatedEntries.add(entry)
            const cbs = callbacks.get(entry.target)
            cbs.forEach(cb => cb([entry], observer))
          }
        }
        // Empty pending queue to allow subsequent triggers
        pendingEntries.length = 0
      })
    }
  })

  return {
    observer,
    subscribe(target: HTMLElement, callback: ResizeObserverCallback) {
      observer.observe(target)
      const cbs = callbacks.get(target) || []
      cbs.push(callback)
      callbacks.set(target, cbs)
    },
    unsubscribe(target: HTMLElement, callback: ResizeObserverCallback) {
      const cbs = callbacks.get(target)
      if (!cbs) { return }
      if (cbs.length < 2) {
        observer.unobserve(target)
        callbacks.delete(target)
        return
      }
      const cbIndex = cbs.indexOf(callback)
      if (cbIndex !== -1) {
        cbs.splice(cbIndex, 1)
        callbacks.set(target, cbs)
      }
    },
  }
}

import React from 'react'
import {
  ApolloQueryResult,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import { ms } from '~/src/lib/date'
import type { TypedQueryContext } from './typed-query-context'
import { getMainDefinition } from '.'

/** Stores timestamp for when a given query Id was last requested */
const ttlQueryIdTimestamps = new Map<string, number>()

function ttlDeltaFor(ttlId: string, now = Date.now()): number {
  const expiresAt = ttlQueryIdTimestamps.get(ttlId)
  return expiresAt ? expiresAt - now : 0
}

export function resetQueryTtl() {
  ttlQueryIdTimestamps.clear()
}

export type UseQueryTtlOptions = {
  /** Unique ID for used for tracking TTL, defaults to name of first operation in provided GraphQL doc */
  ttlId?: string
  /** Time-to-live (milliseconds if number, parsed by `ms(ttl)` if string) */
  ttl?: number | string
  /** Track queries with different variables separately */
  ttlVary?: boolean
  /** Refresh query whenever TTL expires after component is mounted */
  ttlRefresh?: boolean
}

/**
 * Extension of `useQuery()` hook that adds TTL (Time-to-live) support.
 * TTL is specified using the `ttl` parameter, and defaults to 1 hour (`1h`).
 *
 * Stale data is still returned (if present) after the TTL has expired,
 * but a network request will be triggered in the background.
 *
 * The most recent query request is tracked using the `ttlId` parameter, which
 * means that it must be unique with regards to your GraphQL query. The query
 * `variables` will be appended to this id by default, disable this behaviour
 * by passing `ttlVary: false`.
 */
export function useQueryTtl<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> & UseQueryTtlOptions & TypedQueryContext
): QueryResult<TData, TVariables> {

  const {
    ttlId: ttlIdInput,
    ttl = 20 * 60e3,
    ttlVary = true,
    ttlRefresh = false,
    ...queryOptions
  } = options

  // Determine ttlId from query AST if not explicitly provided
  let ttlId = ttlIdInput || (getMainDefinition(query).name?.value as string)
  if (!ttlId) {
    throw new Error(`useQueryTtl: No 'ttlId' provided and unable to infer from query`)
  }

  // Include variables in `ttlId` when `ttlVary` is enabled
  if (ttlVary) {
    let { variables } = options
    const fetchAll = queryOptions.context?.fetchAll
    if (fetchAll) {
      // Exclude $first & $after from composed ttlId when `fetchAll` support is used
      variables = Object.assign({}, variables)
      delete variables[fetchAll.limitVar || 'first']
      delete variables[fetchAll.cursorVar || 'after']
    }
    ttlId += '@' + JSON.stringify(variables, jsonStringifyStableReplacer)
  }

  const ctx = React.useRef({
    mounted: true,
    onCompleted,
    scheduleRefetch,
    refetchTimeout: undefined as any,
    hasTriggeredInitialRefetch: false,
  })
  queryOptions.onCompleted = ctx.current.onCompleted = onCompleted
  ctx.current.scheduleRefetch = scheduleRefetch

  function onCompleted(data: TData): Promise<ApolloQueryResult<TData>> | null {
    // onCompleted only runs **once** until this is fixed:
    // https://github.com/apollographql/apollo-client/issues/5531
    if (ctx.current.hasTriggeredInitialRefetch) {
      const ttlMs = ms(ttl)
      ttlQueryIdTimestamps.set(ttlId, Date.now() + ttlMs)
      scheduleRefetch(ttlMs)
    }
    // @ts-ignore
    return options.onCompleted?.(data) || null
  }

  const q = useQuery(query, queryOptions)

  function scheduleRefetch(delay: number) {
    clearTimeout(ctx.current.refetchTimeout)
    if (
      !ctx.current.mounted || options.skip || !ttlId ||
      (!ttlRefresh && ctx.current.hasTriggeredInitialRefetch)
    ) {
      // console.debug('useQueryTtl', ttlId, 'unscheduling')
      return
    }
    // console.debug('useQueryTtl', ttlId, 'scheduling', delay)
    ctx.current.refetchTimeout = setTimeout(() => {
      const latestDelay = ttlDeltaFor(ttlId)
      if (latestDelay > 100) {
        // console.debug('useQueryTtl', ttlId, 'rescheduling in', latestDelay)
        scheduleRefetch(latestDelay)
      } else {
        // console.debug('useQueryTtl', ttlId, 'automatic refetch')
        q.refetch().then(result => {
          ctx.current.hasTriggeredInitialRefetch = true
          // Only necessary due to `onCompleted` not being called after refetch()
          ctx.current.onCompleted(result.data)
        }).catch(error => {
          // Swallow GraphQLErrors to avoid "uncaught promise rejection",
          // they're handled by useQuery() anyway
        })
      }
    }, delay)
  }

  React.useEffect(() => {
    const context = ctx.current

    // Re-schedule refetch on mount and when ttlId changes
    context.scheduleRefetch(ttlDeltaFor(ttlId))

    return () => {
      // We always want to clear the most recent timeout
      clearTimeout(context.refetchTimeout)
      context.mounted = false
    }
  }, [ttlId])

  return {
    ...q,
    // Only necessary due to `onCompleted` not being called after refetch()
    refetch: React.useCallback(() => {
      // console.debug('useQueryTtl', ttlId, 'manual refetch')
      return q.refetch().then(result => {
        ctx.current.hasTriggeredInitialRefetch = true
        ctx.current.onCompleted(result.data)
        return result
      })
    }, [q.refetch]) // eslint-disable-line react-hooks/exhaustive-deps
  }
}

/**
 * Used with `JSON.stringify(obj, jsonStringifyStableReplacer)` to return
 * a stable JSON representation of `obj`, irregardless of initial property order.
 */
function jsonStringifyStableReplacer(_key: string, value: any): any {
  if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
    value = Object.keys(value).sort().reduce((copy, key) => {
      copy[key] = value[key]
      return copy
    }, {} as Record<string, any>)
  }
  return value
}

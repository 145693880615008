import * as React from 'react'

/**
 * Returns a (memoized) object representation of the query string in the
 * provided `window.location`-like object.
 *
 * @example
 * ```js
 * import { useLocation } from 'react-router-dom'
 * ...
 * const location = useLocation()
 * const queryParams = useQueryParams(location)
 * ```
 *
 * @param location - `window.Location` like object, generally the return value of `useLocation()`.
 * @return Object representation of the query params in the given location.
 */
export function useQueryParams(location: { search: string }) {
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
}

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '~/src/lib/auth'
import { FormError, useForm } from '../lib/form'

export default function Login() {
  const navigate = useNavigate()
  const auth = useAuth()
  const loggedIn = auth.loggedIn()
  const form = useForm({
    onSubmit(data) {
      return auth.login(data.email, data.password)
    },
  })

  React.useEffect(() => {
    loggedIn && navigate('/')
  }, [loggedIn, navigate])

  if (loggedIn) {
    return null
  }

  return (
    <form
      onSubmit={form.onSubmit}
      className="Login flex"
    >
      <h1>Log in</h1>
      <label className="field">
        <div className="field-label">Email address</div>
        <input
          name="email"
          type="text"
          inputMode="email"
          autoComplete="username"
          disabled={form.disabled}
          className="field__wide"
        />
      </label>
      <label className="field">
        <div className="field-label">Password</div>
        <input
          name="password"
          type="password"
          autoComplete="password"
          disabled={form.disabled}
          className="field__wide"
        />
      </label>
      <button
        type="submit"
        disabled={form.disabled}
        children="Log in"
      />
      <FormError form={form} className="mt-1" />
    </form>
  )
}

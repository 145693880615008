import * as React from 'react'
import { cx } from '../lib/classNames'
import { containsElement } from '../lib/dom'
import { Obj } from '../lib/types'

export function Dropdown({
  id,
  className,
  title,
  disabled,
  stayOpenOnInteraction = false,
  stayOpenOnOutsideClick = false,
  childrenProps,
  children = null,
}: {
  id: string
  className?: string
  title: React.ReactNode
  disabled?: boolean
  stayOpenOnInteraction?: boolean
  stayOpenOnOutsideClick?: boolean
  childrenProps?: Obj
  children?: React.ReactNode | ((props: {}) => React.ReactNode)
}) {
  const containerRef = React.useRef<HTMLDivElement>()
  const [open, setOpen] = React.useState(false)
  const btnId = id + '-button'

  // Close dropdown on outside clicks
  React.useEffect(() => {
    if (!open || stayOpenOnOutsideClick) { return }
    const handler = (event: Event) => {
      const container = containerRef.current
      const target = event.target as HTMLElement
      if (target && container && !containsElement(container, target, true)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handler, { passive: true })
    document.addEventListener('touchstart', handler, { passive: true })
    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [open, stayOpenOnOutsideClick])

  return <div ref={containerRef} className={cx('Dropdown', className)}>
    <button
      type="button"
      onClick={() => setOpen(x => !x)}
      id={btnId}
      disabled={disabled}
      className="Dropdown__button"
      aria-haspopup="listbox"
      aria-labelledby={btnId}
      aria-expanded={open ? 'true' : undefined}
      children={title}
    />
    {(open && !disabled) && <div
      role="listbox"
      tabIndex={-1}
      aria-labelledby={btnId}
      id={id}
      className="Dropdown__items"
      onClick={(event) => {
        if (stayOpenOnInteraction) { return }
        const closingElement = (event.target as HTMLElement).closest('a, button')
        if (closingElement) {
          setOpen(false)
        }
      }}
      children={typeof children === 'function'
        ? React.createElement(children as any, childrenProps || {})
        : children
      }
    />}
  </div>
}


import * as React from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { HeaderDoc } from '~/src/graphql'
import { AuthManager, useAuth } from '~/src/lib/auth'
import { formatNumber } from '~/src/lib/number'
import { useNetworkStatus } from '~/src/lib/useNetworkStatus'
import { useCachePersistor } from '~/src/lib/useCachePersistor'
import StatusIndicator from './StatusIndicator'
import Nav, { NavItem } from './Nav'
import { Dropdown } from './Dropdown'

export default function Header(props) {
  const auth = useAuth({ throwWhenMissing: false })

  return <div className="Header toolbar">
    <NavItem to="/" children="Home" />

    {auth?.loggedIn() ? (
      <UserMenu auth={auth} />
    ) : (
      <NavItem to="/login" children="Log in" />
    )}

    <div className="flex-spacer Header__spacer" />

    <Dropdown
      id="debug-dropdown"
      className="DebugDropdown"
      title="Debug"
      stayOpenOnInteraction
      stayOpenOnOutsideClick
      children={DebugDropdownContents}
    />
    <NetworkStatusIndicator />
  </div>
}

function UserMenu({ auth }: { auth: AuthManager }) {
  const query = useQuery(HeaderDoc, {
    variables: { first: 25 },
    context: {
      // fetchAllConnection: 'me.accounts',
      auth: true,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  })

  const latestData = query.data || query.previousData
  const user = latestData?.me?.__typename === 'User' ? latestData.me : null
  const activeAccountId = auth.getAccount()
  const activeAccount = user && activeAccountId
    ? user.accounts?.edges.find(({ node }) => node!.id === activeAccountId)?.node
    : null

  const dropdownTitle = latestData
    ? activeAccount?.businessName || 'Accounts'
    : 'Loading'

  return <>
    <Dropdown
      id="user-dropdown"
      className="UserDropdown"
      title={dropdownTitle}
      disabled={!user}
    >
      {!!user && (
        <h4 className="Dropdown__header UserDropdown__header">
          <span className="UserDropdown__logged-in-as">Logged in as{' '}</span>
          <span title={user.email}>{user.name || user.email}</span>{' '}
          <button onClick={auth.logout} children="log out" />
        </h4>
      )}
      {query.error ? (
        <p className="Dropdown__option error">Error: {query.error.message}</p>
      ) : query.loading ? (
        <p className="Dropdown__option loading">Loading...</p>
      ) : user && user.accounts.edges.length ? (
        user.accounts.edges.map(({ node }) => <NavItem
          key={node.id}
          to={'/account/' + node.id}
          role="option"
          className="Dropdown__option"
          children={node.businessName}
        />)
      ) : (
        <p className="Dropdown__option">No accounts</p>
      )}
    </Dropdown>

    {!!activeAccountId && <Nav
      items={[
        { to: `/account/${activeAccountId}/zones`, children: 'Zones' },
        { to: `/account/${activeAccountId}/library`, children: 'Library' },
      ]}
    />}
  </>
}

function DebugDropdownContents() {
  const client = useApolloClient()
  const cachePersistor = useCachePersistor()
  const [cacheSize, setCacheSize] = React.useState([])

  const updateCacheSize = React.useCallback(() => {
    cachePersistor.getSize().then(size => {
      setCacheSize(existing => {
        return size === existing[0]
          ? existing
          : [size].concat(existing.slice(0, 9))
      })
    })
  }, [cachePersistor])

  React.useEffect(() => {
    updateCacheSize()
    const interval = setInterval(updateCacheSize, 2e3)
    return () => clearInterval(interval)
  }, [updateCacheSize])

  return <>
    <div className="Dropdown__header">
      <button
        type="button"
        onClick={() => {
          const removed = client.cache.gc().length
          console.log(`Garbage collected ${removed} records`)
          updateCacheSize()
        }}
        children="GC"
      />
      <button
        type="button"
        onClick={() => client.resetStore().finally(updateCacheSize)}
        children="Refresh"
      />
    </div>
    <table className="DebugDropdown__table mb-0">
      <tbody>
        {cacheSize.map((size, i) => {
          const prev = cacheSize[i + 1]
          return <tr key={i}>
            <td>{formatNumber(size, '0,000[.]00b')}</td>
            <td style={{ color: size > prev ? 'darkred' : 'darkgreen' }}>
              {!!prev && formatNumber(size - prev, '+0,000[.]0b')}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}

function NetworkStatusIndicator() {
  const state = useNetworkStatus()
  const status = (state.queryError || state.mutationError) ? 'error'
    : state.numPendingQueries + state.numPendingMutations > 0 ? 'warning'
    : 'ok'
  return <StatusIndicator state={status} throbbing={status === 'warning'} />
}

import * as React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function NotFound() {
  const location = useLocation()
  const navigate = useNavigate()

  const error = React.useRef<Error>()
  if (!error.current) {
    error.current = new Error('Page not found')
  }

  const goBack = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <section>
      <h1>Not found</h1>
      <pre>{location.pathname + location.search}</pre>
      <ul className="flat">
        <li>
          <a href="#" onClick={goBack} children="Previous page" />
        </li>
        <li>
          <Link to="/" children="Go to index" />
        </li>
      </ul>
    </section>
  )
}

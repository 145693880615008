"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPositiveInteger = exports.integerToArrayInitializer = exports.objectInitializer = void 0;
var objectInitializer = function () { return ({}); };
exports.objectInitializer = objectInitializer;
var integerToArrayInitializer = function (key, childKey) {
    return isPositiveInteger(childKey) ? [] : {};
};
exports.integerToArrayInitializer = integerToArrayInitializer;
var POSITIVE_INTEGER_REGEX = /^(?:0|[1-9]\d*)$/;
function isPositiveInteger(value) {
    return (typeof value === 'number')
        ? value === Math.floor(value) && value >= 0
        : typeof value === 'string' && POSITIVE_INTEGER_REGEX.test(value);
}
exports.isPositiveInteger = isPositiveInteger;

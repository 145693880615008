import * as React from 'react'

/** @see https://github.com/soundtrackyourbrand/ui/blob/master/sass/_settings.scss */
export const breakpoints = {
  xs: 400,
  s: 600,
  m: 720,
  l: 1000,
  xl: 1280,
  hd: 1600,
}
export const breakpointsArray = Object.values(breakpoints)

export type BreakpointContextType = {
  min: number
  max: number
  mql: any
}

const FALLBACK_BREAKPOINT = {
  min: 0,
  max: Infinity,
  mql: null,
}

const BreakpointContext = React.createContext<BreakpointContextType>(FALLBACK_BREAKPOINT)
BreakpointContext.displayName = 'BreakpointContext'

/**
 * Hook that returns the active breakpoint, updated whenever it changes.
 * Requires a <BreakpointProvider> in the React component hierarchy.
 *
 * @return Active breakpoint
 */
export function useBreakpoint(): number {
  return React.useContext(BreakpointContext).min
}

BreakpointProvider.displayName = 'BreakpointProvider'

/**
 * Context provider for useBreakpoint / <Breakpoint>.
 * Doesn't do or render anything by itself, but attaches the media query
 * listeners which in turn triggers <Breakpoint> updates.
 *
 * Example usage:
 * <Breakpoint.Provider breaks={[400, 720, 1000]}>
 *   Components using useBreakpoint()
 * </Breakpoint.Provider>
 */
export function BreakpointProvider({ breaks, children }: {
  breaks: number[]
  children: React.ReactNode
}) {
  const [active, setActive] = React.useState(FALLBACK_BREAKPOINT)
  const breaksString = breaks.join(',')

  React.useLayoutEffect(() => {
    const checks: Record<string, BreakpointContextType> = {}

    const onUpdate = (event) => {
      if (event.matches) {
        setActive(checks[event.media])
      }
    }

    let min = 0
    let current
    breaksString.split(',').concat('Infinity').forEach(maxStr => {
      const max = maxStr === 'Infinity' ? Number.MAX_VALUE : parseInt(maxStr, 10) - 1
      const mql = window.matchMedia(widthQueryString({ min, max }))
      checks[mql.media] = { min, max, mql }
      mql.addListener(onUpdate)
      if (mql.matches) {
        current = checks[mql.media]
      }
      min = max + 1
    })

    setActive(current)

    return () => {
      Object.keys(checks).forEach(media => {
        checks[media].mql.removeListener(onUpdate)
      })
    }
  }, [breaksString, setActive])

  return <BreakpointContext.Provider value={active} children={children} />
}


/**
 * Generates a CSS @media query string for a given width interval;
 * (min-max), (min) or (max).
 *
 * @param bounds - Min and max widths
 * @return Valid CSS @media query string
 */
function widthQueryString({ min, max }: { min?: number, max?: number }): string {
  const query: string[] = []
  if (min > 0) {
    query.push(`(min-width: ${min}px)`)
  }
  if (max < Number.MAX_VALUE) {
    query.push(`(max-width: ${max}px)`)
  }
  if (!query.length) {
    throw new Error('widthQueryString: Invalid values for min/max')
  }
  return query.join(' and ')
}

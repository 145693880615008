import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { assertData } from '~/src/apollo/helpers'
import { LibraryDoc, ILibraryVariables } from '~/src/graphql'
import Nav from '~/src/components/Nav'
import * as displayable from '../lib/displayable'
import { useQueryTtl } from '../lib/useQueryTtl'
import { DisplayItem } from '../components/DisplayItem'
import { VirtualizedList, VirtualizedListProps } from '../components/VirtualizedList'
import SearchField from '../components/SearchField'
import { useQueryParams } from '../lib/useQueryParams'

const LIBRARY_TYPES = ['playlists', 'schedules'] as const
type LibraryTypes = typeof LIBRARY_TYPES[number]

export default function Library() {
  const params = useParams()
  const libraryType = LIBRARY_TYPES.includes(params['*'] as any)
    ? params['*'] as LibraryTypes
    : LIBRARY_TYPES[0]

  return <div className="Library">
    <section>
      <Nav
        items={[
          { to: '', end: true, children: 'Playlists' },
          { to: 'schedules', children: 'Schedules' },
        ]}
      />
    </section>
    <LibraryList
      key={libraryType} // ensures that query is resolved synchronously when possible
      account={params.account}
      type={libraryType}
    />
  </div>
}

const staticVirtualizedProps: Partial<VirtualizedListProps<any>> = {
  estimateSize: () => 65,
  itemComponent({ item, style, containerRef }) {
    return <DisplayItem item={item.node} style={style} containerRef={containerRef} />
  },
  containerProps: {
    className: 'DisplayList',
  },
}

function LibraryList({ account, type }: {
  account: string
  type: LibraryTypes
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchQuery = useQueryParams(location).get('q') || ''

  const variables: ILibraryVariables = {
    account,
    first: 500,
    playlists: type === 'playlists',
    schedules: type === 'schedules',
  }
  const query = useQueryTtl(LibraryDoc, {
    variables,
    context: {
      fetchAllConnection: `musicLibrary.${type}`,
    },
    ttlId: 'library',
    ttl: '10min',
    ttlRefresh: true,
  })
  const library = assertData(query, `musicLibrary`)?.[type]
  const items = React.useMemo(() => {
    if (!library) {
      return displayable.edgesWithSkeletons(10, null, 'Playlist')
    }
    const queryLower = searchQuery.toLowerCase()
    return searchQuery
      ? library.edges.filter(a => a.node.name.toLowerCase().includes(queryLower))
      : library.edges
  }, [library, searchQuery]) as typeof query.data.musicLibrary.playlists.edges

  return <>
    <SearchField
      query={searchQuery}
      onSearch={query => navigate('?q=' + encodeURIComponent(query), { replace: true })}
      debounce={300}
    />
    <VirtualizedList items={items} {...staticVirtualizedProps} />
  </>
}

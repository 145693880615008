import React from 'react'
import { useQuery } from '@apollo/client'
import {
  IZoneRecentlyPlayedVariables,
  ZoneRecentlyPlayedDoc,
} from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import { Assign } from '~/src/lib/types'
import { endOfDay, formatDate, modifyDay } from '~/src/lib/date'
import * as displayable from '~/src/lib/displayable'
import { DisplayItem } from '~/src/components/DisplayItem'

const INITIAL_ITEMS = 5

type RecentlyPlayedVars = Assign<Partial<IZoneRecentlyPlayedVariables>, {
  startAt: string
}>

export default function ZoneRecentlyPlayed({ soundZone }) {
  const endOfToday = endOfDay()
  const [vars, setVars] = React.useState<RecentlyPlayedVars>({
    startAt: endOfToday.toISOString(),
  })
  const startAtDate = new Date(vars.startAt)
  const isToday = vars.startAt === endOfToday.toISOString()
  const query = useQuery(ZoneRecentlyPlayedDoc, {
    variables: {
      soundZone,
      market: 'US',
      first: INITIAL_ITEMS,
      ...vars,
    },
    fetchPolicy: isToday ? 'cache-and-network' : 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const history = assertData(query, 'soundZone.playbackHistory')

  const navigateDate = (startAt: Date, nextPage = false) => {
    const endOfToday = endOfDay() // recalculate in case last render was a while back
    const next: RecentlyPlayedVars = {
      startAt: endOfDay(startAt).toISOString(),
      after: undefined,
    }
    let fetchMore = false
    if (next.startAt > endOfToday.toISOString()) {
      next.startAt = endOfToday.toISOString()
      fetchMore = true
    }
    if (nextPage && history) {
      next.after = history.pageInfo.endCursor
      next.first = 100
      fetchMore = true
    }
    if (fetchMore) {
      query.fetchMore({ variables: next }).then(() => {
        setVars(next)
      })
    } else {
      setVars(next)
    }
  }

  const isFetching = query.networkStatus === 3
  const isFetchingInBackground = isToday && query.networkStatus === 2
  const items = history?.edges || displayable.edgesWithSkeletons(INITIAL_ITEMS, null, {
    track: displayable.skeletonOf('Track'),
    playFrom: displayable.skeletonOf('Playlist'),
  })

  const buttonOlder = <button
    type="button"
    onClick={() => navigateDate(modifyDay(startAtDate, -1))}
    disabled={isFetching}
    style={{ width: '8em' }}
    children={`← ${formatDate(modifyDay(startAtDate, -1), { weekday: 'long' })}`}
  />
  const buttonNewer = <button
    type="button"
    onClick={() => navigateDate(modifyDay(startAtDate, +1))}
    disabled={isFetching || isFetchingInBackground}
    style={{ width: '8em' }}
    children={isToday
      ? 'Refresh'
      : `${formatDate(modifyDay(startAtDate, +1), { weekday: 'long' })} →`
    }
  />

  return <div className="ZoneRecentlyPlayed">
    <div className="table__actions">
      {buttonOlder}
      <input
        type="date"
        value={startAtDate.toLocaleDateString('sv')}
        onChange={(e) => {
          const date = e.target.valueAsDate
          navigateDate(date && !isNaN(date.getTime()) ? date : endOfToday)
        }}
        max={endOfToday.toLocaleDateString('sv')}
        className="ZoneRecentlyPlayed__date"
      />
      {buttonNewer}
    </div>
    <div
      className={`ZoneRecentlyPlayed__table-wrapper` + (isFetching ? ' loading-overlay' : '')}
    >
      <table className="ZoneRecentlyPlayed__table">
        <thead>
          <tr>
            <th className="ZoneRecentlyPlayed__track">Track</th>
            <th className="ZoneRecentlyPlayed__source">Source</th>
            <th className="ZoneRecentlyPlayed__time">Time</th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ node }, index) => (
            <tr key={node.id || index}>
              <td className="ZoneRecentlyPlayed__track">
                <DisplayItem item={node.track} format="landscape" />
              </td>
              <td className="ZoneRecentlyPlayed__source">
                {node.playFrom && (
                  <DisplayItem item={node.playFrom} format="landscape" />
                )}
              </td>
              <td className="ZoneRecentlyPlayed__time">
                {node.startedAt
                  ? formatDate(node.startedAt, { timeStyle: 'medium' })
                  : <span className="placeholder-text" />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="table__actions">
      {buttonOlder}
      {history?.pageInfo.hasNextPage && (
        <button
          type="button"
          onClick={() => navigateDate(startAtDate, true)}
          disabled={isFetching}
          className="ZoneRecentlyPlayed__loadMore"
          children="Load more"
        />
      )}
      {buttonNewer}
    </div>
  </div>
}

import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'
import { assertData } from '~/src/apollo/helpers'
import { LocationCreateDoc, ZoneCreateDataDoc, ZoneCreateDoc } from '~/src/graphql'
import { FormError, useForm } from '~/src/lib/form'

export default function ZoneCreate() {
  const navigate = useNavigate()
  const params = useParams()
  const apollo = useApolloClient()
  const [locationAction, setLocationAction] = React.useState('add')

  const form = useForm({
    onSubmit(data) {
      return Promise.resolve().then(() => {
        if (data.locationAction === 'add') {
          return apollo.mutate({
            mutation: ZoneCreateDoc,
            variables: {
              input: {
                location: data.location,
                name: data.name,
              },
            },
          })
        } else {
          return apollo.mutate({
            mutation: LocationCreateDoc,
            variables: {
              input: {
                account: params.account,
                address: data.address,
                address2: data.address2,
                city: data.city,
                country: data.country?.toUpperCase(),
                postalCode: data.postalCode,
                state: data.state,
                soundZoneName: data.name,
                name: data.name,
              },
            },
          })
        }
      }).then(() => {
        navigate(`/account/${params.account}/zones`)
      })
    },
  })

  return <div className="ZoneCreate">
    <section>
      <form onSubmit={form.onSubmit}>
        <fieldset disabled={form.disabled}>
          <h2>Add zone</h2>
          <label className="field">
            <div className="field-label">Zone name</div>
            <input
              name="name"
              className="field__wide"
            />
            <FormError form={form} field="name" />
          </label>
          <div className="toolbar">
            <label>
              <input
                type="radio"
                name="locationAction"
                value="add"
                checked={locationAction === 'add'}
                onChange={e => setLocationAction('add')}
              />
              Add to existing location
            </label>

            <label>
              <input
                type="radio"
                name="locationAction"
                value="create"
                checked={locationAction !== 'add'}
                onChange={e => setLocationAction('create')}
              />
              Create new location
            </label>
          </div>
          {locationAction === 'add' ? (
            <LocationSelect
              account={params.account}
              name="location"
              className="field__wide"
            />
          ) : (
            <fieldset>
              <label className="field">
                <div className="field-label">ISO Country code</div>
                <input
                  name="country"
                  minLength={2}
                  maxLength={2}
                  size={4}
                  placeholder="SE"
                />
                <FormError form={form} field="country" />
              </label>
              <label className="field">
                <div className="field-label">Address line 1</div>
                <input
                  name="address"
                  autoComplete="address-line1"
                  className="field__wide"
                />
                <FormError form={form} field="address" />
              </label>
              <label className="field">
                <div className="field-label">Address line 2 (optional)</div>
                <input
                  name="address2"
                  autoComplete="address-line2"
                  placeholder="Suite, unit, building, etc."
                  className="field__wide"
                />
                <FormError form={form} field="address2" />
              </label>
              <label className="field">
                <div className="field-label">Postal code</div>
                <input
                  name="postalCode"
                  autoComplete="postal-code"
                  placeholder="12345"
                  className="field__wide"
                />
                <FormError form={form} field="postalCode" />
              </label>
              <label className="field">
                <div className="field-label">City</div>
                <input
                  name="city"
                  autoComplete="address-level2"
                  className="field__wide"
                />
                <FormError form={form} field="city" />
              </label>
            </fieldset>
          )}
          <div className="toolbar toolbar--baseline mt-2">
            <Link
              to={`/account/${params.account}/zones`}
              children="Cancel"
            />
            <button
              type="submit"
              disabled={false}
              children="Create zone"
            />
          </div>
        </fieldset>
      </form>
    </section>
  </div>
}

function LocationSelect({ account, ...props }) {
  const query = useQuery(ZoneCreateDataDoc, {
    context: {
      fetchAllConnection: 'account.locations',
    },
    variables: { account, first: 500 },
    fetchPolicy: 'cache-and-network',
  })
  const locations = assertData(query, 'account.locations')
  const sorted = locations?.edges.slice().sort(locationSorter)

  return (
    <label className="field">
      <div className="field-label">Location</div>
      <select name="location" {...props}>
        {sorted?.map(l => (
          <option
            key={l.node.id}
            value={l.node.id}
            children={l.node.name}
          />
        ))}
        {query.loading && (
          <option key="loading" value="" children="-- Loading --" />
        )}
      </select>
    </label>
  )
}

function locationSorter(a: any, b: any) {
  return a.node.name.localeCompare(b.node.name)
}

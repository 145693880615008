"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateInImmutable = void 0;
var changeImmutable_1 = require("./changeImmutable");
var initializer_1 = require("./initializer");
var shallowClone_1 = require("./shallowClone");
function updateInImmutable(obj, path, updater, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    if (typeof path === 'string') {
        path = path.split('.');
    }
    if (typeof obj['updateIn'] === 'function') {
        return obj['updateIn'](path, updater);
    }
    if (!path.length) {
        return updater((0, shallowClone_1.shallowClone)(obj));
    }
    return (0, changeImmutable_1.changeImmutable)(obj, path, function (clonedObj, finalPath) {
        clonedObj[finalPath] = updater(clonedObj[finalPath]);
        return clonedObj;
    }, initializer);
}
exports.updateInImmutable = updateInImmutable;

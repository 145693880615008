import * as React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Listens to location #hash changes while mounted, attempting to focus
 * & scroll to the targeted DOM element when such exists.
 *
 * Work around for lack of #hash support in react-router-dom:
 * https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
 */
export const LocationHashScroller = React.memo<{
  /** Temporarily pause the change detection - checks when going from true to false */
  pause?: boolean
  /** Maximum duration of the element polling triggered on hash change */
  timeout?: number
}>(function LocationHashScroller({
  pause = false,
  timeout = 300,
}) {
  const { hash } = useLocation()

  React.useEffect(() => {
    if (pause || !hash || hash === '#') { return }

    // Only way to correctly apply CSS :target pseudo selector...
    // https://stackoverflow.com/a/59013961/1527562
    window.history.pushState('', '', hash)
    history.back()
    history.forward()

    const deadline = Date.now() + timeout
    let currentTimeout: any

    const checkElement = () => {
      const element = document.getElementById(hash.substr(1))

      if (!element) {
        // Retry again later?
        if (Date.now() >= deadline) { return }
        currentTimeout = setTimeout(checkElement, 20)
        return
      }

      element.focus()

      try {
        element.scrollIntoView({ block: 'center', behavior: 'auto' })
      } catch (error) {
        element.scrollIntoView()
      }
    }

    currentTimeout = setTimeout(checkElement, 20)

    return () => {
      clearTimeout(currentTimeout)
    }
  }, [hash, pause, timeout])

  return null
})


import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'
import { assertData } from '~/src/apollo/helpers'
import { ISoundZoneUpdateMutationInput, ZoneEditDataDoc, ZoneEditDoc } from '~/src/graphql'
import { pad as padKey } from '~/src/lib/keys'
import { FormError, useForm } from '~/src/lib/form'

export default function ZoneEdit() {
  const navigate = useNavigate()
  const params = useParams()
  const zoneId = padKey(params.zone)
  const apollo = useApolloClient()
  const query = useQuery(ZoneEditDataDoc, {
    variables: { zone: zoneId },
  })
  const zone = assertData(query, 'soundZone')
  const formRef = React.useRef<HTMLFormElement>()
  const form = useForm({
    formRef,
    initialStatus: 'initializing',
    onSubmit(raw) {
      const input: ISoundZoneUpdateMutationInput = {
        id: zoneId,
        name: raw.name,
        settings: {
          bandwidthLimitationKBPS: parseInt(raw.bandwidthLimitationKBPS, 10) || 0,
          bitrate: raw.bitrate,
          crossfade: parseInt(raw.crossfadeLength, 10) > 0,
          crossfadeLength: parseInt(raw.crossfadeLength, 10) || 0,
          defaultVolume: parseInt(raw.defaultVolume, 10) || 0,
          diskcacheMaxMb: parseInt(raw.diskcacheMaxMb, 10) || 0,
          mono: raw.mono === 'true',
          staffControl: raw.staffControl === 'on',
          volumeEq: raw.volumeEq === 'on',
        },
      }
      return Promise.resolve().then(() => {
        return apollo.mutate({
          mutation: ZoneEditDoc,
          variables: { input },
        })
      }).then(() => {
        navigate(`/account/${params.account}/zones`)
      })
    },
  })


  React.useEffect(() => {
    if (!zone || !formRef.current || form.status !== 'initializing') { return }
    const values = [
      'bandwidthLimitationKBPS',
      'bitrate',
      'crossfadeLength',
      'defaultVolume',
      'diskcacheMaxMb',
      'mono',
      'name',
      'staffControl',
      'volumeEq',
    ].reduce((values, field) => {
      values[field] = zone.settings[field] ?? zone[field]
      return values
    }, {})
    form.setValues(values)
    form.dispatch({ type: 'reset' })
  }, [zone, form, form.status])

  return <div className="ZoneEdit">
    <section>
      <form ref={formRef} onSubmit={form.onSubmit}>
        <fieldset disabled={form.disabled}>
          <h2>Edit zone</h2>
          <label className="field">
            <div className="field-label">Zone name</div>
            <input
              name="name"
              className="field__wide"
            />
            <FormError form={form} field="name" />
          </label>
          <label className="field">
            <input
              name="staffControl"
              type="checkbox"
            />
            Staff control
          </label>
          <div className="field">
            <label>
              <input
                name="mono"
                type="radio"
                value="false"
              />
              Stereo
            </label>
            {' '}
            <label>
              <input
                name="mono"
                type="radio"
                value="true"
              />
              Mono
            </label>
          </div>
          <label className="field">
            <input
              name="volumeEq"
              type="checkbox"
            />
            Volume normalization
          </label>
          <label className="field">
            <div className="field-label">Default volume</div>
            <input
              name="defaultVolume"
              type="range"
              min={0}
              max={16}
              step={1}
              list="defaultVolumeSteps"
            />
            <datalist id="defaultVolumeSteps">
              {Array.apply(null, { length: 5 }).map((_, i) => (
                <option key={i} children={i * 4} />
              ))}
            </datalist>
            <FormError form={form} field="defaultVolume" />
          </label>
          <label className="field">
            <div className="field-label">Crossfade (seconds)</div>
            <input
              name="crossfadeLength"
              type="range"
              min={0}
              max={10}
              step={1}
              list="crossfadeSteps"
            />
            <datalist id="crossfadeSteps">
              {Array.apply(null, { length: 6 }).map((_, i) => (
                <option key={i} children={i * 2} />
              ))}
            </datalist>
            <FormError form={form} field="crossfadeLength" />
          </label>
          <label className="field">
            <div className="field-label">Data usage (music quality)</div>
            <select name="bitrate">
              <option value="extreme" children="Extreme" />
              <option value="high" children="High" />
              <option value="normal" children="Normal" />
              <option value="low" children="Low" />
            </select>
            <FormError form={form} field="bitrate" />
          </label>
          <label className="field">
            <div className="field-label">Bandwidth limitation</div>
            <select name="bandwidthLimitationKBPS">
              <option value="0" children="No limit" />
              <option value="200" children="0.2 Mbit/s" />
              <option value="500" children="0.5 Mbit/s" />
              <option value="1000" children="1 Mbit/s" />
              <option value="2000" children="2 Mbit/s" />
              <option value="4000" children="4 Mbit/s" />
              {/*
                TODO: When syb-core has been updated to accept any valid value
                https://favro.com/organization/86ba35bfb4e08917e2b8226e/a265bbb6882fd18750af0175?card=Sou-39792
                <option value="256" children="0.2 Mbit/s" />
                <option value="512" children="0.5 Mbit/s" />
                <option value="1024" children="1 Mbit/s" />
                <option value="2048" children="2 Mbit/s" />
                <option value="4096" children="4 Mbit/s" />
              */}
            </select>
            <FormError form={form} field="bandwidthLimitationKBPS" />
          </label>
          <label className="field">
            <div className="field-label">Maximum disk usage</div>
            <select name="diskcacheMaxMb">
              <option value="0" children="No limit" />
              <option value="1000" children="1 GB" />
              <option value="2000" children="2 GB" />
              <option value="4000" children="4 GB" />
              <option value="8000" children="8 GB" />
              <option value="12000" children="12 GB" />
              <option value="16000" children="16 GB" />
              <option value="32000" children="32 GB" />
              <option value="64000" children="64 GB" />
              {/*
                TODO: When apps display proper byte values and not multiples of 1000
                <option value="1024" children="1 GB" />
                <option value="2048" children="2 GB" />
                <option value="4096" children="4 GB" />
                <option value="8192" children="8 GB" />
                <option value="12288" children="12 GB" />
                <option value="16384" children="16 GB" />
                <option value="32768" children="32 GB" />
                <option value="65536" children="64 GB" />
              */}
            </select>
            <FormError form={form} field="diskcacheMaxMb" />
          </label>
          <div className="toolbar toolbar--baseline mt-2">
            <Link
              to={`/account/${params.account}/zones/${zoneId}`}
              children="Cancel"
            />
            <button
              type="submit"
              children="Update zone"
            />
          </div>
        </fieldset>
      </form>
    </section>
  </div>
}

import * as React from 'react'
import Search from './search'
import SearchOld from './search-old'

export default function Home() {
  return <>
    <h2><em>New</em> search API</h2>
    <Search />
    <h2><em>Old</em> search API</h2>
    <SearchOld />
  </>
}

import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  IAlbum_List,
  IArtist_List,
  IBrowseCategory_List,
  IDisplay_Image,
  ILibraryItem_Playlist,
  ILibraryItem_Schedule,
  ITrack_List,
} from '~/src/graphql'
import { parse as parseKey } from '~/src/lib/keys'

export type Skeleton = {
  __typename: 'Skeleton'
  __skeleton: true
  id: ''
  [key: string]: unknown
}

export const skeleton: Skeleton = {
  __typename: 'Skeleton',
  __skeleton: true,
  id: '',
} as const

export type Displayable =
 | IAlbum_List
 | IArtist_List
 | IBrowseCategory_List
 | ILibraryItem_Playlist
 | ILibraryItem_Schedule
 | ITrack_List
 | Skeleton

export type DisplayableTypes = Exclude<Displayable['__typename'], 'Skeleton' | undefined>

type Identifiable = {
  __typename?: DisplayableTypes
  id: string
  shortId?: string
}

export function isSkeleton(item: unknown): item is Skeleton {
  return item === skeleton
    || (!!item && typeof item === 'object' && item['__skeleton'] === true)
}

const skeletonCache: Partial<Record<DisplayableTypes, Skeleton>> = {}

export function skeletonOf(__typename: DisplayableTypes): Skeleton {
  if (!skeletonCache[__typename]) {
    skeletonCache[__typename] =
      Object.freeze({ ...skeleton, __typename }) as unknown as Skeleton
  }
  return skeletonCache[__typename]!
}

export function edgesWithSkeletons<Edges extends any[] = any[]>(
  length: number,
  existingEdges?: Readonly<Edges>,
  /** Known displayable table, or custom skeleton object */
  type: DisplayableTypes | Object = skeleton,
) {
  if (existingEdges && existingEdges.length >= length) { return existingEdges }
  const edges: Edges = existingEdges ? existingEdges.slice() : [] as any
  const node = typeof type === 'string'
    ? skeletonOf(type as DisplayableTypes)
    : type
  // Carry over existing cursor if present
  const cursor = edges[edges.length - 1]?.cursor
  for (let i = edges.length; i < length; i++) {
    edges.push({ cursor, node })
  }
  return edges
}

const TYPENAME_TO_ID_TYPE: Partial<Record<DisplayableTypes, string>> = {
  Album: 'album',
  Artist: 'artist',
  BrowseCategory: 'tag',
  Track: 'track',
  /*
  Still identified via syb-core legacy IDs:
  Playlist: 'playlist',
  Schedule: 'schedule',
  Soundtrack: 'soundtrack',
  */
}

const ID_TYPE_TO_TYPENAME = Object.keys(TYPENAME_TO_ID_TYPE).reduce((obj, key) => {
  obj[TYPENAME_TO_ID_TYPE[key]] = key
  return obj
}, {}) as Record<string, keyof typeof TYPENAME_TO_ID_TYPE>

export function expandId(shortId: string, type: string): string
export function expandId(shortId: Identifiable): string
/**
 * Generates a long ID (`soundtrack:$type:$shortId`) from a given `shortId` + `type`.
 * Omits `soundtrack:$type:` prefix for legacy IDs.
 */
export function expandId(shortId: string | Identifiable, type?: string): string {
  if (typeof shortId === 'string' && shortId.startsWith('soundtrack:')) {
    return shortId
  }
  if (typeof shortId === 'object' && shortId) {
    type ||= shortId.__typename
    if (!shortId.shortId) {
      return shortId.id
    }
    shortId = shortId.shortId
  }
  if (type && TYPENAME_TO_ID_TYPE[type]) {
    type = TYPENAME_TO_ID_TYPE[type]
  }
  return ['soundtrack', type, shortId].join(':')
}

/** Length of ID part of a soundtrack URI (`soundtrack:artist:ID`) */
const SOUNDTRACK_URI_ID_LENGTH = 22

/**
 * Parses a long ID (`soundtrack:$type:$shortId`) into `__typename` + `shortId`.
 * Also able to deduce `__typename` and `id` from legacy IDs.
 *
 * Validity of the ID can be determined by checking the presence of
 * `__typename` in the returned object.
 *
 * @param id - ID string to parse
 * @param [strict] - Only consider ids that pass basic sanity checking to be valid
 */
export function parseId(id: string, strict = false): Identifiable {
  const parts = id.split(':')

  // Handle legacy IDs
  if (parts.length === 1 && /[A-Z]/.test(id)) {
    let kind = parseKey(id)?.kind
    if (!kind) {
      return { id }
    }
    if (kind === 'Collection') { kind = 'Playlist' }
    return { __typename: kind as any, id }
  }

  if (parts[0] === 'soundtrack') {
    parts.shift()
    if (strict && parts.length === 2 && parts[1].length !== SOUNDTRACK_URI_ID_LENGTH) {
      return { id }
    }
  }

  const type = parts.length > 1 && ID_TYPE_TO_TYPENAME[parts[0]]
  return type
    ? { __typename: type, id, shortId: parts[1] }
    : { id }
}

export function link(item: Displayable | Identifiable | string): string {
  if (isSkeleton(item)) { return '' }
  const x = (
    typeof item === 'string'
      ? parseId(item)
      : item && item['shortId']
        ? item as Identifiable
        : { ...parseId(item.id), ...item }
  ) as Displayable
  const shortId: string = x['shortId'] || x.id
  switch (x.__typename) {
    case 'Playlist':
    case 'Schedule':
      return `/source/${shortId}`
    case 'Artist':
      return `/artist/${shortId}`
    case 'Album':
      return `/album/${shortId}`
    case 'Track':
      return x.album
        ? `/album/${parseId(x.album.id).shortId}#${x.id}`
        : `/track/${shortId}`
    case 'BrowseCategory':
      return `/tag/${x.slug}`
  }
  return ''
}


export function subtitle(
  item: Displayable,
  { link = false } = {},
): React.ReactNode {
  if (isSkeleton(item)) { return null }
  switch (item.__typename) {
    case 'BrowseCategory':
      return 'Tag'
    case 'Playlist':
      return item.shortDescription || item.__typename
    case 'Schedule':
    case 'Artist':
      return item.__typename
    case 'Album':
      return <>{capitalize(item.albumType || 'Release')} by {artists(item.artists, { link })}</>
    case 'Track':
      return artists(item.artists, { link })
  }
  return null
}

export function display(item: Displayable): IDisplay_Image | null | undefined {
  const display = item['display'] as IDisplay_Image
  if ((typeof display === 'object' && display) || isSkeleton(item)) {
    return display
  }
  switch (item.__typename) {
    case 'Schedule':
      return { colors: { primary: { hex: item.colors?.[0] } } }
  }
  return null
}

export function artists(
  artists: Array<{ id: string, name: string }>,
  { joiner = ', ', link = false } = {},
) {
  if (!Array.isArray(artists)) {
    return 'Unknown'
  }
  const final = []
  let index = -1

  for (const artist of artists) {
    index += 1
    if (index > 0) {
      final.push(
        React.isValidElement(joiner)
          ? React.cloneElement(joiner, { key: `joiner-${index}` })
          : joiner
      )
    }

    final.push(
      link ? (
        // Skip using `link()` since we know the ID format and where to link to
        <Link
          key={artist.id}
          to={'/artist/' + artist.id.replace(/^soundtrack:artist:/, '')}
          title="Go to artist"
          children={artist.name}
        />
      ) : (
        artist.name
      )
    )
  }

  if (typeof joiner === 'string' && !link) {
    return final.join('')
  }
  return final
}

export function capitalize(input: string): string {
  return input[0].toUpperCase() + input.substr(1)
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateIn = void 0;
var initializer_1 = require("./initializer");
function updateIn(obj, path, updater, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    if (typeof path === 'string') {
        path = path.split('.');
    }
    if (typeof obj['updateIn'] === 'function') {
        return obj['updateIn'](path, updater);
    }
    var cursor = obj;
    var i = 0;
    for (; i < path.length - 1; i++) {
        var key = path[i];
        if (key == null || cursor == null) {
            cursor = undefined;
            break;
        }
        if (!cursor[key] && initializer) {
            // Only allocate a cloned `path` array if the initializer takes 3 or more
            // args (and would thus utilize it)
            cursor[key] = initializer.length > 2
                ? initializer(key, path[i + 1], path.slice(0, i + 1))
                : initializer(key, path[i + 1]);
        }
        cursor = cursor[key];
    }
    if (cursor == null) {
        throw new Error("Reached a null node while initialization was disabled (".concat(path.join('.'), ")"));
    }
    var tail = path[i];
    cursor[tail] = updater(cursor[tail]);
    return obj;
}
exports.updateIn = updateIn;

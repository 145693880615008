import * as React from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Listens to location changes while mounted, and automatically scrolls the
 * viewport to the top of the page when the given part(s) of the URL changes.
 *
 * TODO: Should this be re-implemented as a React context based solution to
 * allow child routes to enable/disable the scrolling?
 */
export const LocationChangeScroller = React.memo<{
  pathTriggers?: boolean
  queryTriggers?: boolean
}>(function LocationChangeScroller({
  pathTriggers = false,
  queryTriggers = false,
}) {
  const { pathname, search } = useLocation()
  const old = React.useRef<{ pathname: string, search: string }>({ pathname, search })
  const changed = [
    pathTriggers && pathname !== old.current.pathname,
    queryTriggers && search !== old.current.search,
  ].some(Boolean)
  old.current = { pathname, search }

  React.useEffect(() => {
    changed && window.scrollTo(0, 0)
  })

  return null
})

import * as React from 'react'
import { cx } from '~/src/lib/classNames'
import { Assign } from '~/src/lib/types'

export const SVG_ALIGN = {
  topleft: 'xMinYMin',
  top: 'xMidYMin',
  topright: 'xMaxYMin',
  left: 'xMinYMid',
  center: 'xMidYMid',
  right: 'xMaxYMid',
  bottomleft: 'xMinYMax',
  bottom: 'xMidYMax',
  bottomright: 'xMaxYMax',
} as const

const iconExistsMap: Record<string, boolean> = {}

/**
 * Checks if a specific icon exists in the icon sprite sheet.
 *
 * @param name - Icon name
 */
export function iconExists(name: string | null | undefined) {
  if (!name) { return false }
  const iconName = `icon-${name}`
  return iconExistsMap[iconName] ??= !!document.getElementById(iconName)
}

export type IconProps = Assign<React.SVGProps<SVGElement>, {
  /** Name of the icon to display. */
  name: string
  /** Icon name to use in case icon `name` doesn't exist */
  fallbackName?: string
  /** The size of the icon. Leave empty for default size. */
  size?: 'medium' | 'large' | 'extra-large'
  align?: keyof typeof SVG_ALIGN
  /** Vertically center icon with surrounding text (applies .icon--middle) */
  middle?: boolean
}>

const IconComponent = React.forwardRef<SVGElement, IconProps>(({
  name,
  fallbackName,
  size,
  align,
  middle,
  ...props
}, ref) => {
  if (fallbackName && (!name || !iconExists(name))) {
    name = fallbackName
  }
  const iconName = name ? `icon-${name}` : null
  const alignValue = align ? SVG_ALIGN[align] + ' meet' : null

  props.ref = ref
  props.className = cx('Icon', iconName, {
    [`Icon--${size}`]: !!size, // Empty is regular size (default)
    'Icon--middle': middle,
  }, props.className)

  if (alignValue) {
    props.preserveAspectRatio = alignValue

    if (iconName) {
      // Retrieve viewBox attribute from SVG sprite symbol
      const symbol = document.getElementById(iconName)
      if (symbol) props.viewBox = symbol.getAttribute('viewBox') || undefined
    }
  }

  if (iconName) {
    props.children = <use xlinkHref={`#${iconName}`} href={`#${iconName}`} />
  }

  return React.createElement('svg', props)
})

/**
 * Used to fine-tune vertical alignment of icon with adjacent text within
 * a flexbox container.
 *
 * @example
 * <div className="flex">
 *   <Icon.FlexAligner><Icon name="info" size="medium" middle /></Icon>
 *   <p className="mb-0">Text whose first line should be aligned with icon.</p>
 * </div>
 */
const FlexAligner = (props) => {
  return React.createElement('span', {
    ...props,
    className: cx('Icon__flex-aligner', props.className)
  })
}
FlexAligner.displayName = 'Icon.FlexAligner'

export const Icon = Object.assign(React.memo(IconComponent), {
  displayName: 'Icon',
  FlexAligner,
  exists: iconExists,
})

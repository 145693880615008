import * as React from 'react'
import {
  Navigate,
  NavigateProps,
  Route,
  Routes,
  generatePath,
  useParams,
} from 'react-router-dom'
import Account from './account'
import Album from './album'
import Artist from './artist'
import Home from './home'
import Library from './library'
import Login from './login'
import NotFound from './404'
import SearchOld from './search-old'
import Search from './search'
import Source from './source'
import Zones from './zones'
import Zone from './zones/zone'
import ZoneCreate from './zones/create'
import ZoneEdit from './zones/edit'

export default React.memo(AppRoutes)

function AppRoutes() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="search/*" element={<Search />} />
      <Route path="search-old/*" element={<SearchOld />} />
      <Route path="source/:id" element={<Source />} />
      <Route path="tag/:id" element={<NavigateParams replace to="/search/:id/playlists" />} />
      <Route path="artist/:id" element={<Artist />} />
      <Route path="album/:id" element={<Album />} />
      <Route path="account/:account" element={<Account />}>
        <Route index element={<Navigate replace to="zones" />} />
        <Route path="zones">
          <Route index element={<Zones />} />
          <Route path="create" element={<ZoneCreate />}>
            <Route path=":location" />
          </Route>
          <Route path=":zone">
            <Route index element={<Zone />} />
            <Route path="edit" element={<ZoneEdit />} />
          </Route>
        </Route>
        <Route path="library/*" element={<Library />} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

/**
 * `<Navigate>` version which resolves `:xx` parameters in the `to` string.
 */
function NavigateParams(props: NavigateProps & { to: string }) {
  const params = useParams()
  return React.createElement(Navigate, {
    ...props,
    to: generatePath(props.to, params)
  })
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeImmutableOld = exports.changeImmutable = void 0;
var shallowClone_1 = require("./shallowClone");
var initializer_1 = require("./initializer");
function changeImmutable(obj, path, 
/** Updater should return finalObject after updating finalObject[finalPath] */
updater, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    if (typeof path === 'string') {
        path = path.split('.');
    }
    var pathLength = path.length;
    var newObj = (0, shallowClone_1.shallowClone)(obj);
    var cursor = newObj;
    var i = 0;
    for (; i < pathLength - 1; i++) {
        var key = path[i];
        if (key == null || cursor == null) {
            cursor = undefined;
            break;
        }
        if ((!cursor[key] || typeof cursor[key] !== 'object') && initializer) {
            // Only allocate a cloned `path` array if the initializer takes 3 or more
            // args (and would thus utilize it)
            cursor[key] = initializer.length > 2
                ? initializer(key, path[i + 1], path.slice(0, i + 1))
                : initializer(key, path[i + 1]);
        }
        else {
            cursor[key] = (0, shallowClone_1.shallowClone)(cursor[key]);
        }
        // Update pointer of parent before advancing to child
        cursor = cursor[key];
    }
    if (cursor == null) {
        throw new Error("Reached a null node while initialization was disabled (".concat(path.join('.'), ")"));
    }
    cursor = updater(cursor, path[i]);
    return newObj;
}
exports.changeImmutable = changeImmutable;
function changeImmutableOld(dest, src, path, 
/** Updater should return finalObject after updating finalObject[finalPath] */
updater, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    if (typeof path === 'string') {
        path = path.split('.');
    }
    var currentPath = path[0];
    if (!dest || dest === src) {
        if (src == null) {
            if (!initializer) {
                throw new Error("Reached a null node while initialization was disabled");
            }
            // TODO: Is this really correct?
            dest = initializer(currentPath, currentPath);
        }
        else {
            dest = (0, shallowClone_1.shallowClone)(src);
        }
        if (!dest) {
            return dest;
        }
    }
    if (path.length === 1) {
        return updater(dest, currentPath);
    }
    if (src != null) {
        src = src[currentPath];
    }
    dest[currentPath] = changeImmutableOld(dest[currentPath], src, path.slice(1), updater, initializer);
    return dest;
}
exports.changeImmutableOld = changeImmutableOld;

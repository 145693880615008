import 'core-js/stable'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import AuthCacheManager from './lib/AuthCacheManager'
import { AuthProvider } from './lib/auth'
import { BreakpointProvider, breakpointsArray } from './lib/breakpoints'
import { LibraryContextProvider } from './lib/useLibrary'
import { CachePersistorProvider } from './lib/useCachePersistor'
import {
  apollo,
  authManager,
  persistor as cachePersistor,
  restoreApolloCache,
} from './apollo'
import AppRoutes from './routes'
import ErrorBoundary from './components/ErrorBoundary'
import Header from './components/Header'
import { LocationChangeScroller } from './components/LocationChangeScroller'
import ErrorView from './components/ErrorView'

export default class App extends React.PureComponent {
  state = {
    loading: true,
    error: undefined as Error | undefined,
  }

  client = apollo
  cache = apollo.cache
  cachePersistor = cachePersistor
  authManager = authManager

  componentDidMount() {
    // @ts-ignore
    window.app = this
    Promise.all([
      authManager.loadData(),
      restoreApolloCache(),
    ]).then(() => {
      this.setState({ loading: false, error: null })
    }).catch(error => {
      this.setState({ loading: false, error })
      throw error
    })
  }

  componentDidCatch(error) {
    this.setState({ loading: false, error })
  }

  resetCache() {
    return apollo.clearStore()
  }

  render() {
    if (this.state.loading) {
      return <p>Loading</p>
    }

    if (this.state.error && !this.client) {
      return (
        <main className="content">
          <ErrorView safeMode error={this.state.error} />
        </main>
      )
    }

    return <ApolloProvider client={this.client!}>
      <AuthProvider value={this.authManager!}>
        <CachePersistorProvider value={this.cachePersistor!}>
          <AuthCacheManager auth={this.authManager!} />
          <LibraryContextProvider auth={this.authManager!}>
            <BreakpointProvider breaks={breakpointsArray}>
              <Router>
                <LocationChangeScroller pathTriggers />
                <ErrorBoundary>
                  <Header />
                </ErrorBoundary>
                <main className="content">
                  <ErrorBoundary resetOnNavigation error={this.state.error}>
                    <AppRoutes />
                  </ErrorBoundary>
                </main>
              </Router>
            </BreakpointProvider>
          </LibraryContextProvider>
        </CachePersistorProvider>
      </AuthProvider>
    </ApolloProvider>
  }
}

const app = document.getElementById('app')
ReactDOM.render(<App />, app)

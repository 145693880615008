"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setInImmutable = void 0;
var updateInImmutable_1 = require("./updateInImmutable");
var initializer_1 = require("./initializer");
function setInImmutable(obj, path, value, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    return (0, updateInImmutable_1.updateInImmutable)(obj, path, function () { return value; }, initializer);
}
exports.setInImmutable = setInImmutable;

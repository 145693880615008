import * as React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ITrack_List, TracklistDoc } from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import * as displayable from '~/src/lib/displayable'
import { formatDuration } from '../lib/date'
import { Assign } from '../lib/types'
import { cx } from '../lib/classNames'
import { DisplayItem } from './DisplayItem'

export default function Tracklist({ collection, initialLimit = 10 }) {
  const [showAll, setShowAll] = React.useState(false)
  const query = useQuery(TracklistDoc, {
    variables: {
      playlist: collection,
      market: 'US',
      first: initialLimit,
    },
    notifyOnNetworkStatusChange: true,
  })

  const tracklist = assertData(query, 'playlist.tracks')
  const canShowMore =
    tracklist?.edges.length > initialLimit ||
    tracklist?.pageInfo.hasNextPage
  const items = React.useMemo(() => {
    const items = tracklist?.edges
      || displayable.edgesWithSkeletons(Math.min(initialLimit, 20), null, 'Track') as typeof tracklist.edges
    return showAll ? items : items.slice(0, initialLimit)
  }, [tracklist, showAll, initialLimit])

  return <div className="Tracklist">
    <TracklistTable
      edges={items}
      isSkeleton={!tracklist}
    />
    {canShowMore && (
      <div className="table__actions">
        <button
          type="button"
          onClick={() => {
            query.fetchMore({
              context: {
                fetchAllConnection: 'playlist.tracks',
              },
              variables: {
                after: query.data.playlist.tracks.pageInfo.endCursor,
                first: 500,
              }
            }).then(() => {
              setShowAll(true)
            })
          }}
          disabled={query.loading}
          className="Tracklist__load-more"
          children="Show all"
        />
      </div>
    )}
  </div>
}

export function TracklistTable({
  edges,
  album = true,
  isSkeleton = false,
  skeletonUpTo = false,
  ...tableProps
}: Assign<React.TableHTMLAttributes<HTMLTableElement>, {
  edges: ReadonlyArray<{ node?: ITrack_List }>
  album?: boolean
  isSkeleton?: boolean
  skeletonUpTo?: number | false
}>) {
  if (skeletonUpTo && skeletonUpTo > (edges?.length || 0)) {
    edges = displayable.edgesWithSkeletons(skeletonUpTo, edges, 'Track')
  } else if (!edges) {
    return null
  }

  tableProps.className = cx('Tracklist__table', tableProps.className)

  return (
    <table {...tableProps}>
      <thead>
        <tr>
          <th className="Tracklist__track">Track</th>
          {album && <th className="Tracklist__album">Album</th>}
          <th className="Tracklist__duration">Duration</th>
        </tr>
      </thead>
      <tbody>
        {edges.map(({ node: track }, index) => {
          if (!track) { return null }
          return <tr key={index}>
            <td className="Tracklist__track">
              <DisplayItem item={track} format="landscape" />
            </td>
            {album && (
              <td className="Tracklist__album">
                {isSkeleton ? (
                  <span className="placeholder-text" />
                ) : track.album ? (
                  <Link
                    to={displayable.link(track.album)}
                    children={track.album.title}
                  />
                ) : null}
              </td>
            )}
            <td className="Tracklist__duration">
              {isSkeleton || !track.durationMs ? (
                <span className="placeholder-text" />
              ) : (
                formatDuration(track.durationMs, { hours: false })
              )}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  )
}

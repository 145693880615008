export function parse(str: string, decodeBefore = true) {
  if (decodeBefore) {
    str = decode(str)
  }

  const [self, parent] = str.split('/')
  if (!self) return null
  const [kind, stringID, rawIntID] = self.split(',')
  if (!kind || (!stringID && !rawIntID)) return null
  const intID = parseInt(rawIntID, 36)

  return {
    kind,
    stringID,
    intID,
    parent: parent ? encode(parent) : '',
  }
}

export function isKind(str: string, ...validKinds: string[]) {
  const key = parse(str)
  if (!key) return false
  for (let i = 0; i < validKinds.length; i++) {
    if (key.kind === validKinds[i]) {
      return true
    }
  }
  return false
}

export function decode(input: string) {
  if (typeof input !== 'string') {
    return ''
  }

  input = input.replace(/\./g, '=')
  let decoded

  if (typeof window !== 'undefined' && window.atob) {
    try {
      decoded = window.atob(input)
    } catch (error) {
      if (error.name === 'InvalidCharacterError' || error.name === 'DOMException') {
        return ''
      }
      throw error
    }
  } else if (typeof Buffer !== 'undefined') {
    decoded = Buffer.from(input, 'base64').toString('ascii')
  } else {
    throw new Error(`decode: Environment supports neither 'atob' nor 'Buffer'`)
  }

  return decoded
}

export function encode(input: string) {
  input = input.replace(/\./g, '=')

  if (typeof window !== 'undefined' && window.btoa) {
    return window.btoa(input)
  } else if (typeof Buffer !== 'undefined') {
    return Buffer.from(input).toString('base64')
  }

  throw new Error(`encode: Environment supports neither 'btoa' nor 'Buffer'`)
}

/**
 * Returns the base64 string with padding characters readded (if missing).
 * Also removes anything after # and ? in case they've sneaked through.
 *
 * @param input - base64 string which may have #? suffixes in it
 * @param [stripSuffix] - Toggles stripping of #? suffixes
 * @return Cleaned & padded base64 string
 */
export function pad(input: string, stripSuffix = true) {
  if (typeof input !== 'string') { return input }
  if (stripSuffix) {
    input = input.replace(/[#?].*/, '')
  }
  while (input.length % 4 !== 0) { input += '.' }
  return input
}

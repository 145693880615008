import * as React from 'react'
import { Link } from 'react-router-dom'
import { cx, withModifier } from '../lib/classNames'
import { ElementProps } from '../lib/dom'
import * as displayable from '../lib/displayable'
import DisplayImage from './DisplayImage'

export type DisplayItemProps<
  ItemType extends displayable.Displayable
> = ElementProps<HTMLElement> & {
  item: ItemType
  link?: string | ((item: ItemType) => string) | false
  format?: 'list' | 'landscape' | 'tile'
  containerRef?: React.Ref<HTMLElement>
}

export const DisplayItem = React.memo(function DisplayItem<
  ItemType extends displayable.Displayable
>({
  item,
  link = displayable.link,
  format = 'list',
  containerRef,
  ...attrs
}: DisplayItemProps<ItemType>) {
  const title = item['title'] || item['name']
  const display = displayable.display(item)
  const subtitle = displayable.subtitle(item)
  const skeleton = displayable.isSkeleton(item) ? 'placeholder-text' : null
  if (typeof link === 'function') {
    link = skeleton ? null : link(item)
  }

  const Tag = link ? Link : 'div'
  return <Tag
    ref={containerRef}
    to={link || undefined}
    title={link ? `Go to "${title}"` : title}
    id={item.id}
    {...attrs}
    style={Object.assign({
      '--colorPrimary': display?.colors?.primary?.hex,
      '--colorSecondary': display?.colors?.secondary?.hex,
    }, attrs.style) as React.CSSProperties}
    className={cx(
      attrs.className,
      `DisplayItem DisplayItem--${format} DisplayItem--${item.__typename}`,
    )}
  >
    <DisplayImage
      item={item}
      size={format === 'list' ? 'thumbnail' : 'tile'}
    />
    <div className={withModifier('DisplayItem__text', subtitle && 'subtitled')}>
      <span
        className={cx('DisplayItem__title', skeleton)}
        children={title}
      />
      {(!!subtitle || !!skeleton) && (
        <span
          className={cx('DisplayItem__subtitle', skeleton)}
          children={subtitle}
        />
      )}
    </div>
  </Tag>
})

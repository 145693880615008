import * as React from 'react'
import cx from 'clsx'
import { IBrowseCategoryType } from '../graphql'
import * as displayable from '../lib/displayable'
import { FadedImage } from './FadedImage'
import { Icon } from './Icon'

const SIZES = {
  thumbnail: [150, 150],
  tile: [400, 400],
  normal: [800, 800],
} as const

const TYPE_TO_ICON_MAP: Record<displayable.DisplayableTypes, string> = {
  Album: 'album',
  Artist: 'mic',
  BrowseCategory: 'tag',
  Playlist: 'playlist',
  Schedule: 'calendar',
  Track: 'music',
} as const

const CATEGORY_TYPE_TO_ICON_MAP: Record<IBrowseCategoryType, string> = {
  business: require('~/src/assets/icons/business.png'),
  decade: require('~/src/assets/icons/decade.png'),
  genre: require('~/src/assets/icons/genre.png'),
  sound: require('~/src/assets/icons/sound.png'),
  category: 'icon-tag',
  chart: 'icon-chart',
  energy: 'icon-energy',
  unknown: 'icon-tag',
} as const

export default function DisplayImage({ item, size, ...props }: {
  item: displayable.Displayable,
  size?: keyof typeof SIZES,
} & React.PropsWithChildren<Partial<HTMLElement>>) {
  const type = item.__typename
  const dimensions = SIZES[size] || SIZES.thumbnail
  const display = displayable.display(item)

  let icon = TYPE_TO_ICON_MAP[type]
  let url = display?.image?.placeholder.replace('%w', dimensions[0]).replace('%h', dimensions[1])
  if (item.__typename === 'BrowseCategory') {
    const typeIcon = CATEGORY_TYPE_TO_ICON_MAP[item.type]
    if (typeIcon && typeIcon.startsWith('icon-')) {
      icon = typeIcon.replace('icon-', '')
    } else if (typeIcon) {
      url = typeIcon
    }
  }

  return React.createElement('div', {
    ...props,
    className: cx(`DisplayImage DisplayImage--${type} DisplayImage--${size}`, {
      'DisplayImage--empty': !url,
      'placeholder': displayable.isSkeleton(item),
    }, props.className),
    style: {
      '--colorPrimary': display?.colors?.primary?.hex,
      '--colorSecondary': display?.colors?.secondary?.hex,
    },
    children: <>
      {!!url && <FadedImage
        src={url}
        width={dimensions[0]}
        height={dimensions[1]}
        loading="lazy"
        alt=""
        className="DisplayImage__image"
      />}
      {!!icon && <Icon name={icon} className="DisplayImage__icon" />}
      {props.children}
    </>,
  })
}

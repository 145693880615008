const MINUTE = 60
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const YEAR = 365.25 * DAY

/**
 * Formats a date + time according to the current locale settings.
 *
 * @param input - Input to format
 * @return Formatted string
 */
export function formatDate(
  input: Date | string,
  options: Intl.DateTimeFormatOptions = { dateStyle: 'medium', timeStyle: 'medium' },
): string {
  const date = input instanceof Date ? input : new Date(input)
  return date.toLocaleString(undefined, options)
}

/**
 * Returns a date formatted as a syb-core legacy "iso8601" string:
 * `YYYYMMDDHHmmss`
 *
 * @param {Date|string} date - Input to format
 * @return {string} Formatted string
 */
export function dateToLegacyFormat(date) {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return date.toISOString().split('.')[0].replace(/[^0-9]/g, '')
}

/**
 * Returns a human readable string representing how far back the given date is.
 * Falls back to printing out regular dates for dates further back then 30 days.
 *
 * @param input - Date to convert
 * @return Human readable string such as '5 minutes ago'
 */
export function relativeTime(
  input: Date | string,
): string {
  const delta = Math.round((Date.now() - new Date(input).getTime()) / 1e3)
  let parts: [number, string] | undefined = undefined
  if (delta < 20) {
    return 'just now'
  } else if (delta < 60) {
    return delta + ' seconds ago'
  } else if (delta < HOUR) {
    parts = [60, 'minute']
  } else if (delta < DAY) {
    parts = [HOUR, 'hour']
  } else if (delta < 7 * DAY) {
    parts = [DAY, 'day']
  } else if (delta < 30 * DAY) {
    parts = [7 * DAY, 'week']
  } else if (delta < YEAR) {
    parts = [30 * DAY, 'month']
  }
  if (!parts) {
    return formatDate(input, { dateStyle: 'medium' })
  }
  const count = Math.floor(delta / parts[0])
  return `${count} ${parts[1]}${(count > 1) ? 's' : ''} ago`
}

export function ms(input: string | number): number {
  if (typeof input === 'number' && isFinite(input)) {
    return input
  }
  if (typeof input !== 'string') {
    throw new Error(`ms: Value is not a string or number`)
  }
  const re = /^(-?(?:\d+)?\.?\d+) *(millisecond|msec|ms|second|sec|s|minute|min|m|hour|hr|h|day|d|week|w|year|yr|y)?s?$/i
  const match = re.exec(input)
  if (!match) {
    return NaN
  }
  const n = parseFloat(match[1])
  const type = (match[2] || 'ms').toLowerCase()
  switch (type) {
    case 'year':
    case 'yr':
    case 'y':
      return n * YEAR * 1e3
    case 'week':
    case 'w':
      return n * 7 * DAY * 1e3
    case 'day':
    case 'd':
      return n * DAY * 1e3
    case 'hour':
    case 'hr':
    case 'h':
      return n * HOUR * 1e3
    case 'minute':
    case 'min':
    case 'm':
      return n * MINUTE * 1e3
    case 'second':
    case 'sec':
    case 's':
      return n * 1e3
    case 'millisecond':
    case 'msec':
    case 'ms':
      return n
    default:
      throw new Error(`ms: The unit ${type} was matched, but no matching case exists`)
  }
}

/**
 * Returns a human readable string representing a relative duration.
 *
 * @param seconds - Duration length
 * @return Human readable string such as '4 hours'
 */
export function informalDuration(seconds: number, limitToHours = false): string {
  let parts: [number, string] = [1, 'second']
  if (seconds < 60) {
    // noop
  } else if (seconds < HOUR) {
    parts = [60, 'minute']
  } else if (seconds < DAY || limitToHours) {
    parts = [HOUR, 'hour']
  } else if (seconds < 7 * DAY) {
    parts = [DAY, 'day']
  } else if (seconds < 30 * DAY) {
    parts = [7 * DAY, 'week']
  } else if (seconds < YEAR) {
    parts = [30 * DAY, 'month']
  } else {
    parts = [YEAR, 'year']
  }
  const count = Math.floor(seconds / parts[0])
  return `${count} ${parts[1]}${(count > 1) ? 's' : ''}`
}

/**
 * Formats a duration in milliseconds to a readable format.
 *
 * @param ms - Duration in millseconds
 * @param [key='momentDuration'] - i18next key to translate with
 * @param [msPrecision=0] - Millisecond precision (appended to seconds)
 * @return Formatted duration.
 */
export function formatDuration(ms: number, {
  hours = true,
  minutes = true,
  seconds = true,
  msPrecision = 0,
}: {
  hours?: boolean,
  minutes?: boolean,
  seconds?: boolean,
  msPrecision?: number,
} = {}) {
  const parts: Array<string | number> = []
  if (hours) {
    parts.push(Math.floor(ms / 3600e3))
  }
  if (minutes || (seconds && hours)) {
    const m = Math.floor(ms / 60e3)
    parts.push(hours ? leftPad(m % 60, 2) : m)
  }
  if (seconds) {
    let s = ms / 1e3
    if (minutes) { s %= 60 }
    let sFixed = s.toFixed(msPrecision)
    if (minutes && s < 10 && sFixed.indexOf('.') < 2) {
      sFixed = '0' + sFixed
    }
    parts.push(sFixed)
  }
  return parts.join(':')
}

function leftPad(str: string | number, length: number, char = '0'): string {
  str = String(str)
  while (str.length < length) {
    str = char + str
  }
  return str
}

/**
 * Returns a new `Date` instance corresponding to the current date at
 * 23:59:59.999 in local time.
 *
 * @param date - `Date` instance or parseable date string.
 * @return `Date` instance representing end of day.
 */
export function endOfDay(date?: Date | string) {
  const result = date ? new Date(date) : new Date()
  result.setHours(23, 59, 59, 999)
  return result
}

/**
 * Returns a copy of `date` inc-/decremented by the `dayModifier`.
 *
 * @param date - `Date` instance to start with.
 * @param dayModifier - Integer value to use as day modifier.
 * @return New `Date` instance
 */
export function modifyDay(date: Date | string, dayModifier: number) {
  const result = date ? new Date(date) : new Date()
  result.setDate(result.getDate() + dayModifier)
  return result
}

import * as React from 'react'
import { useQuery } from '@apollo/client'
import { Dropdown } from '~/src/components/Dropdown'
import { assertData } from '../apollo/helpers'
import { PlayButtonZonesDoc, PlaySourceDoc } from '../graphql'
import { useAuth } from '../lib/auth'
import { ZoneStatus, ZoneFilters, generateFilters } from './ZoneFilters'
import { AsyncButton } from './AsyncButton'

type PlayButtonProps = {
  source: string
}

export const PlayButton: React.FC<PlayButtonProps> = ({
  source,
  ...props
}) => {
  const auth = useAuth()
  const account = auth.getAccount()
  const [working, setWorking] = React.useState(false)

  if (!account) { return null }

  return <Dropdown
    id={`play-button-${source}`}
    title="Play"
    className="PlayButton"
    disabled={working}
    childrenProps={{ source, account, setWorking }}
    children={PlayButtonContents}
  />
}

PlayButton.displayName = 'PlayButton'

type ZoneFilter = { query: string, status: ZoneStatus }
const DEFAULT_FILTER: ZoneFilter = {
  query: '',
  status: 'paired',
}

function PlayButtonContents({
  source,
  account,
  setWorking,
}: {
  source: string
  account: string
  setWorking: (working: boolean) => void
}) {
  const [filters, setFilters] = React.useState(DEFAULT_FILTER)
  const query = useQuery(PlayButtonZonesDoc, {
    variables: {
      account,
      first: 30,
      filters: generateFilters(filters),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })
  const zones = assertData(query, 'account.soundZones')

  const handleSelect = (soundZone: string) => {
    setWorking(true)
    query.client.mutate({
      mutation: PlaySourceDoc,
      variables: {
        input: { soundZone, source },
      },
    }).finally(() => {
      setWorking(false)
    })
  }

  return <>
    <h4 className="Dropdown__header">Select zone</h4>
    <div className="PlayButton__toolbar" onClick={stopPropagation}>
      <ZoneFilters
        {...filters}
        onUpdate={(field, value) => {
          if (field == null) {
            setFilters(DEFAULT_FILTER)
          } else {
            const next = { ...filters }
            next[field] = value
            setFilters(next)
          }
        }}
      />
    </div>
    <div
      className="PlayButton__zones"
      style={{ opacity: query.networkStatus < 3 ? '0.4' : undefined }}
    >
      {zones?.edges.map(({ node }) => (
        <p key={node.id} className="Dropdown__option">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              handleSelect(node.id)
            }}
            role="option"
            children={node.name}
          />
          {node.playFrom?.id === source ? (
            ` (assigned)`
          ) : !node.online ? (
            ` (offline)`
          ) : node.playFrom?.name ? (
            ` (playing ${node.playFrom.name})`
          ) : (
            ` (idle)`
          )}
        </p>
      ))}
      {!zones?.edges.length && (
        <p
          className="Dropdown__option"
          children={zones
            ? 'No matching zones found'
            : 'Loading zones...'
          }
        />
      )}
      {zones?.pageInfo.hasNextPage && (
        <AsyncButton
          onClick={(e) => {
            e.stopPropagation()
            return query.fetchMore({
              variables: { cursor: zones.pageInfo.endCursor },
            })
          }}
          className="mt-1"
          children="Load more"
        />
      )}
    </div>
  </>
}

function stopPropagation(event) {
  event.stopPropagation()
}

import { FieldPolicy, Reference } from '@apollo/client/cache'
import { mergeDeep } from '@apollo/client/utilities/common/mergeDeep'
import { __rest } from 'tslib'

type KeyArgs = FieldPolicy<any>['keyArgs']

// Whether TRelayEdge<TNode> is a normalized Reference or a non-normalized
// object, it needs a .cursor property where the customRelayPagination
// merge function can store cursor strings taken from pageInfo. Storing an
// extra reference.cursor property should be safe, and is easier than
// attempting to update the cursor field of the normalized StoreObject
// that the reference refers to, or managing edge wrapper objects
// (something I attempted in #7023, but abandoned because of #7088).
export type TRelayEdge<TNode> = {
  cursor?: string
  node: TNode
} | (Reference & { cursor?: string })

export type TRelayPageInfo = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor: string
  endCursor: string
}

export type TExistingRelay<TNode> = Readonly<{
  edges: TRelayEdge<TNode>[]
  pageInfo: TRelayPageInfo
}>

export type TIncomingRelay<TNode> = {
  edges?: TRelayEdge<TNode>[]
  pageInfo?: TRelayPageInfo
}

export type RelayFieldPolicy<TNode> = FieldPolicy<
  TExistingRelay<TNode> | null,
  TIncomingRelay<TNode> | null,
  TIncomingRelay<TNode> | null
>

/**
 * Re-implementation of built-in `relayStylePagination()` which respects the
 * `first` argument.
 *
 * @see https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts
 */
export function relayPagination<TNode = Reference>(
  /** Identical to regular relayStylePagination keyArgs */
  keyArgs: KeyArgs = false,
  options: {
    /** Return all cached entries instead of respecting `$first/$after`? */
    all?: boolean,
    /** Allow query to be resolved by partially filled cache (`hasNextPage && edges.length < $first`)? */
    partial?: boolean,
  } = {},
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,

    read(existing, { canRead, readField, args }) {
      if (!existing) { return existing }
      const cachedCount = existing.edges.length

      const edges: TRelayEdge<TNode>[] = []

      let firstEdgeCursor = ''
      let lastEdgeCursor = ''
      const {
        startCursor,
        endCursor,
        hasNextPage,
        // hasPreviousPage,
      } = existing.pageInfo || {}

      if (
        !options.partial && hasNextPage &&
        args!.first && args!.first > cachedCount
      ) {
        // Trigger fetch since the query requested more entries than what is cached
        return undefined
      }

      let i = 0
      if (args!.after) {
        const indexAfter = existing.edges.findIndex((x) => x.cursor === args!.after)
        if (indexAfter >= 0) {
          i = indexAfter + 1
        }
      }

      const first = options.all || !args!.first
        ? cachedCount - i
        : Math.min(args!.first, cachedCount - i)

      for (; i < first; i++) {
        const edge = existing.edges[i]
        if (canRead(readField('node', edge))) {
          edges.push(edge)
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || ''
            lastEdgeCursor = edge.cursor || lastEdgeCursor
          }
        }
      }

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          // If existing.pageInfo.{start,end}Cursor are undefined or '', default
          // to firstEdgeCursor and/or lastEdgeCursor.
          startCursor: startCursor || firstEdgeCursor,
          endCursor: endCursor || lastEdgeCursor,
        },
      }
    },

    merge(existing, incoming, { args, isReference, readField }) {
      if (!existing) {
        existing = makeEmptyData()
      }

      if (!incoming) {
        return existing
      }

      const incomingEdges = incoming.edges ? incoming.edges.map(edge => {
        if (isReference(edge = { ...edge })) {
          // In case edge is a Reference, we read out its cursor field and
          // store it as an extra property of the Reference object.
          edge.cursor = readField<string>('cursor', edge)
        }
        return edge
      }) : []

      if (incoming.pageInfo) {
        const { pageInfo } = incoming
        const { startCursor, endCursor } = pageInfo
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // In case we did not request the cursor field for edges in this
        // query, we can still infer cursors from pageInfo.
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor
        }
        // Cursors can also come from edges, so we default
        // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.
        const firstCursor = firstEdge && firstEdge.cursor
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          })
        }
        const lastCursor = lastEdge && lastEdge.cursor
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          })
        }
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (args && args.after) {
        // This comparison does not need to use readField('cursor', edge),
        // because we stored the cursor field of any Reference edges as an
        // extra property of the Reference object.
        const index = prefix.findIndex(edge => edge.cursor === args.after)
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (args && args.before) {
        const index = prefix.findIndex(edge => edge.cursor === args.before)
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else if (incoming.edges) {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [
        ...prefix,
        ...incomingEdges,
        ...suffix,
      ]

      const pageInfo: TRelayPageInfo = {
        // The ordering of these two ...spreads may be surprising, but it
        // makes sense because we want to combine PageInfo properties with a
        // preference for existing values, *unless* the existing values are
        // overridden by the logic below, which is permitted only when the
        // incoming page falls at the beginning or end of the data.
        ...incoming.pageInfo,
        ...existing.pageInfo,
      }

      if (incoming.pageInfo) {
        const {
          hasPreviousPage, hasNextPage,
          startCursor, endCursor,
          ...extras
        } = incoming.pageInfo

        // If incoming.pageInfo had any extra non-standard properties,
        // assume they should take precedence over any existing properties
        // of the same name, regardless of where this page falls with
        // respect to the existing data.
        Object.assign(pageInfo, extras)

        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo. Note that these updates are
        // only permitted when the beginning or end of the incoming page
        // coincides with the beginning or end of the existing data, as
        // determined using prefix.length and suffix.length.
        if (!prefix.length) {
          if (undefined !== hasPreviousPage) { pageInfo.hasPreviousPage = hasPreviousPage }
          if (undefined !== startCursor) { pageInfo.startCursor = startCursor }
        }
        if (!suffix.length) {
          if (undefined !== hasNextPage) { pageInfo.hasNextPage = hasNextPage }
          if (undefined !== endCursor) { pageInfo.endCursor = endCursor }
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      }
    },
  }
}

// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras)
const notExtras = ['edges', 'pageInfo']

function makeEmptyData(): TExistingRelay<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { assertData } from '~/src/apollo/helpers'
import { ZoneDeleteDoc, ZoneDoc, ZoneInitiatePairingDoc, ZoneUnpairDoc } from '~/src/graphql'
import { pad as padKey } from '~/src/lib/keys'
import SoundZoneRow from '~/src/components/SoundZoneRow'
import ZoneRecentlyPlayed from '~/src/components/ZoneRecentlyPlayed'
import NowPlaying from '~/src/components/NowPlaying'
import { AsyncButton } from '~/src/components/AsyncButton'

export default function Zone() {
  const navigate = useNavigate()
  const params = useParams()
  const zoneId = padKey(params.zone)
  const query = useQuery(ZoneDoc, {
    variables: { zone: zoneId },
    returnPartialData: true,
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
    fetchPolicy: 'cache-and-network', // revalidate on mount if cached
  })
  const zone = assertData(query, 'soundZone')
  const isPaired = !!zone?.device && !!(zone.device.name || zone.device.label)

  const handlePairing = () => {
    if (!zone?.id) { return }
    const hasDevice = zone?.device
    const isRefreshing = !!(hasDevice && zone.device.pairingCode)
    return query.client.mutate({
      mutation: hasDevice ? ZoneUnpairDoc : ZoneInitiatePairingDoc,
      variables: { zone: zone.id },
    }).then(() => {
      if (isRefreshing) {
        return query.client.mutate({
          mutation: ZoneInitiatePairingDoc,
          variables: { zone: zone.id },
        })
      }
    })
  }

  return <div className="Zone">
    <section>
      {!!zone?.__typename && <>
        <SoundZoneRow soundZone={zone} />
        <div className="toolbar toolbar--baseline">
          <Link
            to={`/account/${params.account}/zones/${zoneId}/edit`}
            children="Edit"
          />
          <AsyncButton
            disabled={!zone?.id}
            onClick={() => {
              if (!confirm(`Are you sure you wish to delete the sound zone "${zone.name}"?`)) {
                return Promise.resolve(false)
              }
              return query.client.mutate({
                mutation: ZoneDeleteDoc,
                variables: { zone: zone.id },
              }).then(() => {
                navigate(`/account/${params.account}/zones`)
                return new Promise(() => {})
              })
            }}
            children="Delete"
          />
        </div>
        {isPaired ? (
          <dl className="SoundZoneRow__status">
            <dt>Playback:</dt>
            <dd>{zone.playback && zone.playback.state}</dd>
            <dt>Source:</dt>
            <dd>
              <Link to={`/source/${zone.playFrom.id}`} children={zone.playFrom.name} />
              {' '}({zone.playFrom.__typename})
            </dd>
            <dt>Player:</dt>
            <dd>
              {zone.device.name || zone.device.label}{' '}
              (v{zone.device.softwareVersion}){' '}
              <AsyncButton
                onClick={handlePairing}
                children="Unpair"
              />
            </dd>
          </dl>
        ) : (
          <dl className="SoundZoneRow__status">
            <dt>Pairing code:</dt>
            <dd>
              {zone.device?.pairingCode && <>
                <code style={{ fontSize: '140%', fontWeight: 'bold' }}>
                  {zone.device.pairingCode.substr(0, 3)}
                  {' - '}
                  {zone.device.pairingCode.substr(3, 3)}
                </code>
                {' '}
              </>}
              <AsyncButton
                onClick={handlePairing}
                children={zone.device ? 'Refresh' : 'Generate'}
              />
            </dd>
          </dl>
        )}
        <NowPlaying
          nowPlaying={zone.nowPlaying}
          soundZoneId={zone.id}
          style="cover"
        />
      </>}
    </section>
    <section>
      <ZoneRecentlyPlayed soundZone={zoneId} />
    </section>
  </div>
}

import * as React from 'react'
import { ApolloError } from '@apollo/client'
import { Obj } from './types'
import { cx } from './classNames'

export type UseFormAction =
  | { type: 'reset' }
  | { type: 'submit' }
  | { type: 'success' }
  | { type: 'error', error: Error }

export type UseFormStatus = 'initializing' | 'idle' | 'working' | 'error'

export type UseFormState<Data extends Obj> = {
  status: UseFormStatus
  error?: ApolloError | Error
  fieldErrors?: Partial<Record<keyof Data, any>>
}

function useFormReducer<Data extends Obj>(
  state: Readonly<UseFormState<Data>>,
  action: UseFormAction,
): Readonly<UseFormState<Data>> {
  switch (action.type) {
    case 'reset':
      return { status: 'idle' }
    case 'submit':
      return { status: 'working' }
    case 'success':
      return { status: 'idle' }
    case 'error':
      return {
        status: 'error',
        error: action.error,
        fieldErrors: validationErrors(action.error as any) as any,
      }
  }
  return state
}

export function useForm<Data extends Obj>(options: {
  onSubmit: (data: Data, event: React.FormEvent<HTMLFormElement>) => any,
  formRef?: React.RefObject<HTMLFormElement>,
  initialStatus?: UseFormStatus,
}) {
  const [state, dispatch] = React.useReducer(useFormReducer, {
    status: options.initialStatus || 'idle',
  })
  const stateRef = React.useRef(state)
  stateRef.current = state
  const optionsRef = React.useRef(options)
  optionsRef.current = options

  const setValues = React.useCallback((data: Partial<Data>) => {
    const form = optionsRef.current.formRef?.current
    if (!form) {
      throw new Error(`useForm.setValues() requires formRef to be provided`)
    }
    Object.keys(data).forEach(key => {
      const value = data[key]
      const element = form[key] as HTMLInputElement
      if (element) {
        if (element.type === 'checkbox') {
          element.checked = value
        } else {
          element.value = String(value)
        }
      } else {
        console.warn(`useForm: Can't update value of unknown field '${key}'`)
      }
    })
  }, [])

  const onSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { status } = stateRef.current
    if (status === 'initializing' || status === 'working') { return false }
    const data = serialize(event.target as HTMLFormElement)
    const result = optionsRef.current.onSubmit(data as Data, event)
    if (result && result?.then) {
      dispatch({ type: 'submit' })
      result.then(res => {
        dispatch({ type: 'success' })
        return res
      }).catch(error => {
        dispatch({ type: 'error', error })
        throw error
      })
    }
  }, [])

  return {
    dispatch,
    onSubmit,
    setValues,
    disabled: state.status === 'initializing' || state.status === 'working',
    ...state
  }
}


export function FormError({ form, field, ...props }: React.HTMLAttributes<HTMLElement> & {
  form: ReturnType<typeof useForm>,
  field?: string,
}) {
  const message = field
    ? form.fieldErrors?.[field]
    : form.error?.message
  if (!message) { return null }
  props.className = cx('FormError', props.className)
  return <p
    role="alert"
    {...props}
    children={message}
  />
}


export function serialize(form: HTMLFormElement): Obj {
  return Object.fromEntries(new FormData(event.target as any) as any)
}


export function validationErrors(error: ApolloError): null | Obj {
  return error?.graphQLErrors?.[0].extensions?.exception?.['validationErrors'] || null
}

import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ISchedule_WithSlots } from '~/src/graphql'
import { withModifier } from '~/src/lib/classNames'
import * as displayable from '~/src/lib/displayable'
import { DisplayList } from '../components/DisplayList'
import { formatDuration } from '../lib/date'

export function SchedulePreview({ schedule }: {
  schedule: ISchedule_WithSlots
}) {
  const location = useLocation()
  const activeDay = React.useMemo(() => {
    return new URLSearchParams(location.search).get('day') ||
      // Default to current week day
      WEEK_DAYS[(new Date().getDay() + 6) % 7]
  }, [location.search]) as WeekDay

  const slotsByDay = React.useMemo(() => {
    const byDay = WEEK_DAYS.reduce((obj, day) => {
      obj[day] = []
      return obj
    }, {}) as Record<WeekDay, ISchedule_WithSlots['slots']>

    // Group by day
    if (schedule.slots?.length) {
      schedule.slots.forEach(slot => {
        const ruleParts = slot.rrule.match(/\bBYDAY=([A-Z]+)/i)
        if (!ruleParts) { return }
        const day = WEEK_DAYS[RRULE_DAYS_TO_INDEX[ruleParts[1]]]
        if (!day) { return }
        byDay[day].push(slot)
      })
    }

    // Sort by starting time
    WEEK_DAYS.forEach(day => {
      byDay[day].sort((a, b) => {
        return (Number(a.start) - 6e4) - (Number(b.start) - 6e4)
      })
    })
    return byDay
  }, [schedule.slots])

  // Lookup table to avoid O(n^2) complexity when printing slot contents
  const playlistById = React.useMemo(() => {
    return (schedule.playlists || []).reduce((obj, playlist) => {
      obj[playlist.id] = playlist
      return obj
    }, {})
  }, [schedule.playlists])

  return <>
    <section>
      <nav className="Nav toolbar">
        {WEEK_DAYS.map(day => (
          <Link
            key={day}
            to={'?day=' + day}
            replace
            className={withModifier('Nav__item', activeDay === day && 'active')}
            children={displayable.capitalize(day)}
          />
        ))}
      </nav>
    </section>
    <section>
      {slotsByDay[activeDay]?.length ? (
        slotsByDay[activeDay].map(slot => {
          const startH = slot.start.substr(0, 2)
          const startM = slot.start.substr(2, 2)
          const end = new Date(slot.duration)
          end.setUTCHours(end.getUTCHours() + Number(startH))
          end.setUTCMinutes(end.getUTCMinutes() + Number(startM))
          return <div key={slot.id}>
            <h4>
              {startH}:{startM} - {end.toISOString().substr(11, 5) + ' '}
              ({formatDuration(slot.duration, { seconds: false })})
            </h4>
            <DisplayList
              edges={slot.collections.map((id, index) => {
                return { node: playlistById[id] }
              })}
              allowDuplicates
            />
          </div>
        })
      ) : schedule.slots ? (
        <p>Schedule contains no slots for this day</p>
      ) : (
        <p>Loading...</p>
      )}
    </section>
  </>
}

const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const
const RRULE_DAYS = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'] as const
const RRULE_DAYS_TO_INDEX = RRULE_DAYS.reduce((obj, day, index) => {
  obj[day] = index
  return obj
}, {})

type WeekDay = typeof WEEK_DAYS[number]

import * as React from 'react'
import { useQuery } from '@apollo/client'
import {
  MusicLibraryIdsDoc,
  MusicLibraryIdsSubscriptionDoc,
  AddToLibraryDoc,
  RemoveFromLibraryDoc,
} from '../graphql'
import { AuthManager } from './auth'

export type LibraryContextType = Readonly<{
  loading: boolean
  account?: string
  array: string[]
  includes: (sourceId: string) => boolean
  toggle: (sourceId: string, remove?: boolean) => Promise<boolean>
}>

export const LibraryContext = React.createContext<LibraryContextType>(null)
LibraryContext.displayName = 'LibraryContext'

/**
 * Exposes the music library to any component nested within
 * a <LibraryContextProvider>.
 */
export function useLibrary() {
  return React.useContext(LibraryContext)
}

/**
 * Fetches the music library of the active account.
 * Returns methods of checking if any entity belongs to the active library,
 * along with the ability to toggle the presence (via GQL mutations).
 */
export function LibraryContextProvider({ auth, children }: {
  auth: AuthManager
  children: React.ReactNode
}) {
  const [account, setAccountId] = React.useState(auth.getAccount)
  const query = useQuery(MusicLibraryIdsDoc, {
    variables: { id: account },
    skip: !account,
  })

  // Re-render (and thus re-fetch) when active account changes
  React.useEffect(() => {
    return auth?.subscribe((event) => {
      setAccountId(auth.getAccount())
    })
  }, [auth])

  const context = React.useMemo(() => {
    const data = () => query.data?.musicLibrary
    return {
      loading: query.loading,
      account: data()?.id,
      array: data()?.ids || [],
      includes: (sourceId: string) => {
        return data()?.ids.includes(sourceId)
      },
      toggle: ((sourceId: string, remove?: boolean) => {
        const d = data()
        if (!d) {
          return Promise.reject(new Error(
            `Can't toggle source in library before it's been retrieved`
          ))
        }
        if (remove == null) {
          remove = d.ids.includes(sourceId)
        }
        return query.client.mutate({
          mutation: remove ? RemoveFromLibraryDoc : AddToLibraryDoc,
          variables: {
            input: {
              parent: d.id,
              source: sourceId,
            }
          },
          // optimisticResponse: {}
        }).then(() => true)
      }),
    }
  }, [query])

  React.useEffect(() => {
    if (!account) { return }
    return query.subscribeToMore({
      document: MusicLibraryIdsSubscriptionDoc,
      variables: { id: account },
    })
  }, [query, account])

  return <LibraryContext.Provider
    value={context}
    children={children}
  />
}

import { FieldReadFunction, KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies'
import { relayStylePagination } from '@apollo/client/utilities'
import { parseId } from '~/src/lib/displayable'
import { IMusicLibrary, StrictTypedTypePolicies } from '../graphql'
import { relayPagination as relayStrictPagination } from './relayPagination'

/**
 * Generates entity references from objects only having an `id`.
 */
const resolveById: FieldReadFunction = (existing, { args, toReference }) => {
  return existing || toReference(args!.id)
}

/**
 * Default cache key resolver.
 * Can also be used as `keyFields` in a TypePolicy.
 */
export const dataIdFromObject: KeyFieldsFunction = ({ __typename, id }) => {
  return id as string
}

/** `dataIdFromObject` that can be used as `keyFields` value without TS whining about it */
const keyOnId = dataIdFromObject as any

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function addOrRemoveFromEdges(params: {
  connection: { edges: any[] },
  connectionTypename: string,
  adding: boolean,
  ref: string,
}) {
  if (!params.connection) { return undefined }
  let found = false
  const edges = params.connection.edges.filter(edge => {
    const matches = edge.node.__ref === params.ref
    if (!found && matches) { found = true }
    return matches ? params.adding : true
  })
  if (params.adding && !found) {
    edges.unshift({
      __typename: params.connectionTypename,
      node: { __ref: params.ref },
    })
  }
  return { ...params.connection, edges }
}

export const typePolicies: Readonly<StrictTypedTypePolicies> = {
  Query: {
    fields: {
      account: resolveById,
      album: resolveById,
      artist: resolveById,
      node: resolveById,
      playlist: resolveById,
      schedule: resolveById,
      search: relayStrictPagination(['type', 'query', 'market']),
    },
  },
  User: {
    keyFields: keyOnId,
    fields: {
      accounts: relayStrictPagination(),
    },
  },
  Account: {
    keyFields: keyOnId,
    fields: {
      soundZones: relayStrictPagination(['filters']),
      locations: relayStrictPagination(),
    },
  },
  Location: {
    keyFields: keyOnId,
    fields: {
      soundZones: relayStrictPagination(['filters']),
    },
  },
  SoundZone: {
    keyFields: keyOnId,
    fields: {
      playbackHistory: relayStylePagination(['startAt']),
    },
  },
  Device: {
    keyFields: keyOnId,
    merge(existing, incoming, c) {
      // Update soundZone.device field
      if (c.variables?.zone) {
        c.cache.modify({
          id: c.variables.zone,
          fields: {
            device: (value, ctx) => {
              return incoming
            },
          },
        })
      }
      return c.mergeObjects(existing, incoming)
    },
  },
  NowPlaying: {
    keyFields: ['soundZone'],
  },
  MusicLibrary: {
    keyFields: ['id'],
    merge: (existing, incoming: Partial<IMusicLibrary>, c) => {
      if (c.isReference(incoming)) {
        // One or both of the objects may be a reference
        existing ||= incoming
        incoming = {
          ids: c.readField('ids', incoming) as any,
        }
      }
      if (incoming.ids) {
        // Track which IDs that are already present in the cached connections
        const idMap = incoming.ids.reduce((idMap, id) => {
          idMap[id] = false
          return idMap
        }, Object.create(null))
        ;['playlists', 'schedules'].forEach(type => {
          const existingType = c.readField(type, existing) as any
          if (existingType) {
            // Re-create connections, omitting any removed entries
            incoming[type] = {
              ...existingType,
              edges: existingType.edges.filter(edge => {
                const id = c.readField('id', edge?.node) as string
                if (id && id in idMap) {
                  idMap[id] = true
                  return true
                }
                return false
              }),
            }
          }
        })
        // Check if there were any entries that wasn't present in the cache,
        // and if so mark the corresponding connection as uncached.
        for (const id in idMap) {
          if (!idMap[id]) {
            const type = parseId(id).__typename
            if (!type) { return }
            incoming[type === 'Schedule' ? 'schedules' : 'playlists'] = undefined
          }
        }
      }
      return c.mergeObjects(existing, incoming)
    },
    fields: {
      playlists: relayStylePagination(),
      schedules: relayStylePagination(),
    },
  },
  BrowseCategory: {
    keyFields: ['id'], // BrowseCategory ids are not globally unique
  },
  Schedule: {
    keyFields: keyOnId,
  },
  Playlist: {
    keyFields: keyOnId,
    fields: {
      tracks: relayStylePagination(['market']),
    },
  },
  Artist: {
    keyFields: keyOnId,
    fields: {
      albums: relayStrictPagination(['albumType', 'market']),
    },
  },
  Album: {
    keyFields: keyOnId,
    fields: {
      tracks: relayStylePagination(['market']),
    },
  },
  Mutation: {
    fields: {
      soundZoneDelete: {
        merge(_, incoming, c) {
          // TODO: Invalidate zones list of account (currently unknown)?
          c.cache.evict({ id: c.variables!.zone })
        },
      },
    },
  },
}

import * as React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '~/src/lib/auth'
import { parse as parseKey } from '~/src/lib/keys'
import ErrorView from '~/src/components/ErrorView'

Account.displayName = 'Account'

export default function Account() {
  const navigate = useNavigate()
  const params = useParams()

  const auth = useAuth()
  const loggedIn = auth.loggedIn()
  const activeAccountId = auth.getAccount() || null
  const requestedAccountId = loggedIn ? params.account : null

  const triggerUpdate = React.useReducer(x => x + 1, 0)[1]
  const error = React.useRef<Error>()
  if (loggedIn) {
    error.current = null
  } else {
    error.current ||= new Error(`You must log in to access this page`)
  }

  // TODO: useAuth() is supposed to trigger a re-render automatically, but for
  // some reason it doesn't appear to work for <EnsureAuthenticated> after
  // logging out and then refreshing the page
  React.useEffect(() => {
    return auth.subscribe((event) => {
      switch (event) {
        // case 'login':
        // auth.setAccount(activeAccountId)
        // break
        case 'logout':
          navigate('/')
          break
        default:
          triggerUpdate()
      }
    })
  }, [auth, navigate, triggerUpdate])

  React.useEffect(() => {
    if (requestedAccountId === activeAccountId) { return }

    const errorMsg = `Invalid account id in URL: '${requestedAccountId}'`
    try {
      const key = parseKey(requestedAccountId)
      if (key.kind !== 'Account') { throw new Error(errorMsg) }
      // Triggers re-render via auth subscription above
      auth.setAccount(requestedAccountId)
    } catch (error) {
      if (error.name === 'DOMException' || error.message === errorMsg) {
        console.warn(errorMsg)
      } else {
        throw error
      }
    }
  }, [auth, requestedAccountId, activeAccountId])

  return error.current
    ? <ErrorView error={error.current} />
    : <Outlet />
}

import * as React from 'react'

export default function SearchField(props: {
  /** Active query */
  query: string
  /** Function to call when search is submitted */
  onSearch: (query: string) => void
  /** Automatically trigger `onSearch()` after a certain delay? */
  debounce?: number
  /** Optional additional search form fields (rendered between input and submit button) */
  children?: React.ReactNode
}) {
  const inputRef = React.useRef<HTMLInputElement>()
  const debouncedTimeout = React.useRef<NodeJS.Timeout>()

  const onSearchRef = React.useRef(props.onSearch)

  React.useEffect(() => {
    onSearchRef.current = props.onSearch
  }, [props.onSearch])

  const commit = React.useCallback((query = inputRef.current.value) => {
    clearTimeout(debouncedTimeout.current)
    debouncedTimeout.current = null
    onSearchRef.current(query)
  }, [])

  React.useLayoutEffect(() => {
    if (!debouncedTimeout.current) {
      inputRef.current.value = props.query
    }
  }, [props.query])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        commit()
      }}
      className="SearchField toolbar"
    >
      <input
        ref={inputRef}
        type="search"
        name="query"
        autoComplete="off"
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Escape':
              commit(inputRef.current.value = '')
              break
            case 'Enter':
              commit(inputRef.current.value)
              break
          }
        }}
        onChange={(event) => {
          if (!props.debounce) { return }
          clearTimeout(debouncedTimeout.current)
          if (event.target.value !== props.query) {
            debouncedTimeout.current = setTimeout(commit, props.debounce)
          }
        }}
        placeholder="Search..."
        className="SearchField__input flex-grow"
      />
      {props.children}
      <button
        type="submit"
        className="SearchField__submit"
        children="Search"
      />
    </form>
  )
}

import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { SourceDoc, IPlaylist, ISchedule } from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import * as displayable from '~/src/lib/displayable'
import { cx } from '~/src/lib/classNames'
import * as keys from '~/src/lib/keys'
import { useLibrary } from '~/src/lib/useLibrary'
import { informalDuration, relativeTime } from '~/src/lib/date'
import Tracklist from '~/src/components/Tracklist'
import DisplayImage from '~/src/components/DisplayImage'
import { DisplayList } from '~/src/components/DisplayList'
import { SchedulePreview } from '~/src/components/SchedulePreview'
import { AsyncButton } from '~/src/components/AsyncButton'
import { PlayButton } from '~/src/components/PlayButton'

export default function Source() {
  const library = useLibrary()

  const variables = { id: keys.pad(useParams().id!) }
  const idKind = React.useMemo(() => keys.parse(variables.id)?.kind, [variables.id])
  const query = useQuery(SourceDoc, {
    variables,
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
    // fetchPolicy: 'cache-and-network', // revalidate on mount if cached
    returnPartialData: true,
  })

  let node = assertData(query, 'node') as IPlaylist | ISchedule | null
  // Guard against empty partial data
  if (!node?.__typename) { node = null }

  if (!query.loading && !node?.name) {
    throw new Error(`Unexpected source type (${node?.__typename})`)
  }

  const display = node || displayable.skeletonOf(idKind === 'Schedule' ? idKind : 'Playlist')
  const cxSkeleton = node ? undefined : 'placeholder-text'

  return <div className="Source">
    <section className="toolbar PageHeader">
      <DisplayImage item={display} size="normal" className="PageHeader__image" />
      <div className="PageHeader__meta">
        <h2
          className={cx('PageHeader__title', cxSkeleton)}
          children={display.name}
        />
        <p className={cx('Source__description', cxSkeleton)}>
          {display.__typename === 'Playlist' ? display.description : node?.__typename}
        </p>
        {!!library.account && <div className="toolbar">
          <PlayButton source={variables.id} />
          <AsyncButton
            onClick={library.loading ? undefined : () => library.toggle(variables.id)}
            children={library.loading
              ? '...'
              : library.includes(variables.id) ? 'Bookmarked' : 'Bookmark'
            }
          />
        </div>}
        {node?.__typename === 'Playlist' && <>
          <div className="toolbar Source__tags">
            {node.genres?.edges.map(({ node }) => (
              <Link
                key={node.name}
                to={`/search/${encodeURIComponent(node.name)}/playlists`}
                children={node.name}
              />
            ))}
          </div>
        </>}
      </div>
    </section>

    {display.__typename === 'Playlist' && <>
      <section className="toolbar Source__stats">
        {display.trackStatistics ? (
          <>
            <p>{display.trackStatistics.playable} tracks</p>
            <p>{informalDuration(display.trackStatistics.playableDuration || 0, true)}</p>
            {!!display.updatedAt && (
              <p>Updated {relativeTime(display.updatedAt)}</p>
            )}
          </>
        ) : (
          <p className={cxSkeleton} />
        )}
      </section>
      <section>
        <Tracklist key={variables.id} collection={variables.id} />
      </section>
      {display.similarPlaylists && display.similarPlaylists.edges.length > 0 && (
        <section>
          <h2>Similar playlists</h2>
          <DisplayList
            edges={display.similarPlaylists.edges}
          />
        </section>
      )}
    </>}

    {display.__typename === 'Schedule' && (
      <SchedulePreview schedule={display} />
    )}
  </div>
}

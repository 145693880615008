import React from 'react'
import { NavLink } from 'react-router-dom'

type NavItemProps = React.ComponentPropsWithRef<typeof NavLink>

Nav.displayName = 'Nav'
Nav.Item = NavItem

export default function Nav({ items }: { items: NavItemProps[] }) {
  return <nav className="Nav toolbar">
    {items.map((props, index) => {
      return <NavItem key={(props.to as string) || index} {...props} />
    })}
  </nav>
}

NavItem.displayName = 'NavItem'

export function NavItem(props: NavItemProps) {
  return <NavLink
    className={({ isActive }) => 'Nav__item' + (isActive ? ' Nav__item--active' : '')}
    {...props}
  />
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIn = void 0;
// FIXME: Can't get this to work - path array ends up returning `never`
/*
export function getIn<Data, Path extends readonly string[]>(
  obj: Data,
  path: Path,
): GetWithPath<Data, Path>
*/
function getIn(obj, path) {
    if (!path || !path.length) {
        return obj;
    }
    if (!obj) {
        return undefined;
    }
    if (typeof path === 'string') {
        path = path.split('.');
    }
    if (typeof obj['getIn'] === 'function') {
        return obj['getIn'](path);
    }
    return path.reduce(function (tree, path) {
        if (!tree)
            return undefined;
        return (typeof tree['get'] === 'function')
            ? tree['get'](path)
            : tree[path];
    }, obj);
}
exports.getIn = getIn;

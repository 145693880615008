import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { assertData } from '~/src/apollo/helpers'
import { SearchOldDoc, SearchOldTypeDoc } from '~/src/graphql'
import * as displayable from '~/src/lib/displayable'
import SearchField from '~/src/components/SearchField'
import { DisplayList } from '../components/DisplayList'

const SEARCH_TYPES = {
  categories: 'category',
  playlists: 'playlist',
  artists: 'artist',
  albums: 'album',
  tracks: 'track',
} as const
type SearchTypePlural = keyof typeof SEARCH_TYPES

const SEARCH_TYPE_TO_TYPENAME: Record<
  SearchTypePlural,
  displayable.DisplayableTypes
> = {
  categories: 'BrowseCategory',
  playlists: 'Playlist',
  artists: 'Artist',
  albums: 'Album',
  tracks: 'Track',
} as const

export default function SearchOld() {
  const navigate = useNavigate()

  const params = (useParams()['*'] || '').split('/')
  const searchQuery = decodeURIComponent(params[0])
  const searchType = SEARCH_TYPES[params[1]]
    ? params[1] as SearchTypePlural
    : null

  return <>
    <section>
      <SearchField
        query={searchQuery}
        onSearch={query => navigate(queryPath(query, searchType), { replace: true })}
        debounce={500}
      />
    </section>
    {searchQuery && searchType ? (
      <SearchType searchQuery={searchQuery} type={searchType} />
    ) : searchQuery ? (
      <SearchAll searchQuery={searchQuery} />
    ) : (
      <section>
        <p>Search for artists, playlists, genres or sounds by using the input field above.</p>
      </section>
    )}
  </>
}

/**
 * Search results overview listing all searchable types.
 */
function SearchAll({ searchQuery }: { searchQuery: string }) {
  const resultsPerSection = 3
  const query = useQuery(SearchOldDoc, {
    variables: {
      query: searchQuery,
      market: 'US',
      first: resultsPerSection,
    },
  })
  assertData(query)

  return <>
    {Object.keys(SEARCH_TYPES).map((type: SearchTypePlural) => {
      const edges = query.data?.[type]?.edges
      if (edges && !edges.length) { return null }

      return <section key={type} className={`Search__result-type Search__${type}`}>
        <div className="toolbar toolbar--baseline">
          <h2>{displayable.capitalize(type)}</h2>
          <Link
            to={queryPath(searchQuery, type)}
            children="All results"
          />
        </div>
        <DisplayList
          edges={edges as any} // FIXME: Album missing id, title from IAlbum_List
          skeletonUpTo={edges?.length ? 0 : (type === 'categories' ? 1 : resultsPerSection)}
          skeletonType={SEARCH_TYPE_TO_TYPENAME[type]}
        />
      </section>
    })}
  </>
}

/**
 * Search results for a specific content type
 */
function SearchType({ searchQuery, type }: {
  searchQuery: string,
  type: SearchTypePlural,
}) {
  const initialFirst = 50
  const [first, setFirst] = React.useState(initialFirst)
  const query = useQuery(SearchOldTypeDoc, {
    variables: {
      query: searchQuery,
      market: 'US',
      type: SEARCH_TYPES[type],
      first,
    },
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
  })
  const results = assertData(query, 'results')
  const edges = results?.edges || []

  const willFetchMore = results
    && edges.length < first
    && results.pageInfo.hasNextPage
  const triggerFetchMore = willFetchMore && !query.loading

  React.useEffect(() => {
    if (triggerFetchMore) {
      query.fetchMore({
        variables: { first: initialFirst }
      })
    }
  }, [triggerFetchMore, query])

  return (
    <section className={`Search__result-type Search__${type}`}>
      <div className="toolbar toolbar--baseline">
        <Link
          to={queryPath(searchQuery)}
          children="Back"
        />
        <h2>{displayable.capitalize(type)}</h2>
      </div>
      <DisplayList
        edges={edges}
        skeletonUpTo={willFetchMore || !results ? 10 : 0}
        skeletonType={SEARCH_TYPE_TO_TYPENAME[type]}
      />
      {results?.pageInfo.hasNextPage && (
        <button
          type="button"
          onClick={() => {
            query.fetchMore({
              variables: { after: results.pageInfo.endCursor, first: 100 },
            }).then(() => {
              setFirst(first => first + 100)
            })
          }}
          disabled={query.networkStatus === 3}
          children="Load more"
        />
      )}
    </section>
  )
}

function queryPath(query: string, type?: SearchTypePlural): string {
  return '/search-old/' + encodeURIComponent(query) + (type ? '/' + type : '')
}

import * as React from 'react'
import { Link } from 'react-router-dom'
import { IEditorialPage, IEditorialSectionEdge } from '../graphql'
import * as displayable from '../lib/displayable'
import { useResizeObserver } from '../lib/resizeObserver'
import { DisplayList } from './DisplayList'

export function idToPath(query: string, type?: string): string {
  if (query.startsWith('search:')) {
    const queryParts = query.split(':')
    query = decodeURIComponent(queryParts[1])
    type ||= queryParts[3]
  } else if (!type) {
    const id = displayable.parseId(query, true)
    if (id.__typename) {
      return displayable.link(id)
    }
  }
  return '/search/' + encodeURIComponent(query) + (type ? '/' + type : '')
}

export type ComponentProps = {
  key?: any
  section: IEditorialSectionEdge['node']
  page?: IEditorialPage
  density?: number
  componentName: string
  index: number
  width: number
}

const DISPLAY_ITEM_MIN_WIDTH = {
  tile: 160,
  list: 240,
} as const

const GenericList = React.memo<ComponentProps & {
  rows?: number
  columns?: number
}>(({
  page,
  section,
  density,
  columns,
  rows = 0,
  width,
  componentName,
}) => {
  const itemMinWidth = DISPLAY_ITEM_MIN_WIDTH[componentName] || 200
  const { edges } = section.items

  columns = columns || Math.min(Math.floor(width / itemMinWidth), density)

  if (rows === -1) {
    rows = Math.max(1, Math.floor(edges.length / columns))
  }

  let linkToMore = section.items.pageInfo.hasNextPage && section.id !== page?.id
  let renderedEdges = edges
  if (rows > 0 && rows * columns < edges.length) {
    renderedEdges = edges.slice(0, rows * columns)
    linkToMore = true
  }

  return <section>
    <div className="toolbar toolbar--baseline">
      <h2>{section.title}</h2>
      {linkToMore && (
        <Link
          to={idToPath(section.id)}
          children="All results"
        />
      )}
    </div>
    <DisplayList
      edges={renderedEdges}
      columns={columns}
      format={componentName as any}
    />
  </section>
})

GenericList.displayName = 'GenericList'

const COMPONENT_MAP: Readonly<Record<
  string,
  React.ComponentType<ComponentProps>
>> = {
  List(props) {
    return <GenericList
      {...props}
      columns={Math.min(Math.floor(props.width / 240), props.density)}
      componentName="list"
    />
  },
  Grid(props) {
    return <GenericList {...props} componentName="tile" />
  },
  FilledGrid(props) {
    return <GenericList
      {...props}
      rows={-1}
      componentName={props.width < 600 ? 'list' : 'tile'}
    />
  },
}

export const SectionRenderer = React.memo<{
  page: IEditorialPage
  edges: IEditorialSectionEdge[]
}>(function SectionRenderer({ page, edges }) {
  const containerRef = React.useRef<HTMLDivElement>()
  const [width, setWidth] = React.useState(0)

  React.useLayoutEffect(() => {
    setWidth(containerRef.current.getBoundingClientRect().width)
  }, [])

  useResizeObserver(containerRef, (entry) => {
    setWidth(entry.contentRect.width)
  })

  return <div ref={containerRef} className="SectionRenderer">
    {!!width && edges?.map((section, index) => {
      for (let i = 0; i < section.node.component.length; i++) {
        const componentName = section.node.component[i]
        if (COMPONENT_MAP[componentName]) {
          const props: ComponentProps = {
            key: index,
            index,
            componentName,
            page,
            section: section.node,
            density: section.density,
            width,
          }
          return React.createElement(COMPONENT_MAP[componentName], props)
        }
      }
      console.log('<SectionRenderer> unhandled', section.node)
      return null
    })}
  </div>
})

import clsx from 'clsx'

export const cx = clsx

export function modifier(
  base: string,
  ...modifiers: any[]
): string {
  return modifiers.reduce((res, m) => {
    if (m) {
      res.push(`${base}--${m}`)
    }
    return res
  }, []).join(' ')
}

export function withModifier(
  base: string,
  ...modifiers: any[]
): string {
  const mod = modifier(base, ...modifiers)
  return mod ? (base + ' ' + mod) : base
}


import * as React from 'react'
import { NavLinkProps, useNavigate, useParams } from 'react-router-dom'
import { assertData } from '~/src/apollo/helpers'
import { SearchDoc } from '~/src/graphql'
import { useQueryTtl } from '~/src/lib/useQueryTtl'
import { withModifier } from '~/src/lib/classNames'
import SearchField from '~/src/components/SearchField'
import Nav from '../components/Nav'
import { idToPath, SectionRenderer } from '../components/SectionRenderer'

export default function Search() {
  const navigate = useNavigate()

  const params = (useParams()['*'] || '').split('/')
  const searchQuery = decodeURIComponent(params[0])
  const searchType = params[1] || undefined

  return <>
    <SearchField
      query={searchQuery}
      onSearch={query => navigate(idToPath(query))}
      debounce={500}
    />
    {searchQuery ? (
      <SearchResults searchType={searchType} searchQuery={searchQuery} />
    ) : (
      <section className="mt-2">
        <p>Search for artists, playlists, genres or sounds by using the input field above.</p>
      </section>
    )}
  </>
}

/**
 * Search results overview listing all searchable types.
 */
function SearchResults({ searchType, searchQuery }: {
  searchType?: string
  searchQuery: string
}) {
  let queryId = `search:${encodeURIComponent(searchQuery)}`
  if (searchType) {
    queryId += `:section:${searchType}`
  }
  const query = useQueryTtl(SearchDoc, {
    variables: { id: queryId },
    returnPartialData: true,
    ttlId: 'search',
  })
  const page = assertData(query, 'editorialSearch') || query.previousData?.editorialSearch

  // Generate tab items
  const tabItems: NavLinkProps[] = [{
    to: idToPath(searchQuery),
    replace: true,
    end: true,
    children: 'All',
  }]
  const tabs = page?.tabs
  const tabsOrPrevious = React.useRef<typeof tabs>(tabs)
  if (tabs) { tabsOrPrevious.current = tabs }
  if (tabsOrPrevious.current && page?.query === searchQuery) {
    tabsOrPrevious.current.forEach(tab => {
      tabItems.push({
        to: idToPath(tab.id),
        replace: true,
        children: tab.display?.title,
      })
    })
  }

  return <div className={withModifier('SearchResults', query.loading && 'loading')}>
    <section className="SearchResults__tabs">
      <Nav items={tabItems} />
    </section>
    <div className="SearchResults__sections">
      <SectionRenderer page={page} edges={page?.sections.edges} />
    </div>
  </div>
}

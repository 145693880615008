import { ApolloLink, Observable, FetchResult } from '@apollo/client'
import { createOperation } from '@apollo/client/link/utils'
import { Observer, ObservableSubscription } from '@apollo/client/utilities'
import type { GraphQLError } from 'graphql'
import { getIn, updateIn } from '@soundtrackyourbrand/object-utils.js'

/** Type for `context.fetchAll` passed to `useQuery()`/`apollo.query()` */
export type FetchAllOptions = {
  /**
   * Dot-delimeted path from the root of the query down to the paginated relay
   * connection containing `pageInfo` and `edges`.
   */
  path: string
  /** Number of edges request in each page query using the `limitVar` (defaults to `variables[limitVar]`) */
  perPage?: number
  /** Name of query variable that controls page size (defaults to `first`) */
  limitVar?: string
  /** Name of query variable used for cursor pagination (defaults to `after`) */
  cursorVar?: string
}

/**
 * Link which enables seamless "fetch all pages" support for paginated fields
 * that adhere to the relay connection spec.
 *
 * This link intercepts outgoing queries that have provided
 * a `fetchAll` object on the apollo query context.
 * A `fetchAll.path` string should be included, representing the dot-delimeted
 * path from the root of the query down to the paginated relay connection
 * containing `pageInfo` and `edges`. This is used by the link to look up
 * `pageInfo.hasNextPage` and `pageInfo.endCursor` to determine if more pages
 * should be fetched, which then happens recursively. All pages are then
 * aggregated into a single query result, as if they were retrieved in a single
 * query.
 *
 * The GQL query must accept variables for limit (= `$first`) and cursor
 * (= `$after`) which should then be passed to the paginated field. The names
 * of these variables can be customized via the options (see `FetchAllOptions`).
 *
 * XXX: This link depends on the custom pagination behaviour provided by
 * `app/apollo/relayPagination.ts`, and may thus not work as expected when
 * querying fields that doesn't use it in `app/apollo/typePolicies.ts`.
 *
 * @example Immediately fetch all (account) pages for a query
 * ```
 * const query = useQuery(gql`
 *   query UserAccounts($first: Int!, $after: String) {
 *     me {
 *       accounts(first: $first, after: $after) {
 *         pageInfo { hasNextPage endCursor } # required
 *         edges {
 *           cursor
 *           node { id }
 *         }
 *       }
 *     }
 *   }
 * `, {
 *   context: {
 *     fetch: { path: 'me.accounts', perPage: 100 },
 *   },
 * })
 * ```
 *
 * @example Manually fetch remaining pages
 * ```
 * const [showAll, setShowAll] = React.useState(false)
 *
 * const query = useQuery(gql`
 *   query UserAccounts($first: Int!, $after: String) {
 *     me {
 *       accounts(first: $first, after: $after) {
 *         pageInfo { hasNextPage endCursor } # required
 *         edges {
 *           cursor
 *           node { id }
 *         }
 *       }
 *     }
 *   }
 * `, {
 *   variables: { first: showAll ? undefined : 10 }, // initially fetch 10 items
 * })
 *
 * const items = React.useMemo(() => {
 *   const edges = query.data?.me.accounts.edges
 *   return showAll ? edges : edges?.slice(0, 10)
 * }, query.data, showAll)
 *
 * const fetchRemaining = () => {
 *   query.fetchMore({
 *     context: {
 *       fetchAll: {
 *         path: 'me.accounts',
 *         perPage: 50, // can fetch more items per page (if backend allows)
 *       }
 *     },
 *     variables: {
 *       after: query.data.me.accounts.pageInfo.endCursor,
 *     },
 *   }).then(() => {
 *     setShowAll(true)
 *   })
 * }
 * ```
 */
export const fetchAllLink = new ApolloLink((
  operation,
  forward,
): Observable<FetchResult> => {
  const ctx = operation.getContext()
  if (!ctx.fetchAll) {
    return forward(operation)
  }

  const {
    path,
    perPage = (operation.variables?.[ctx.fetchAll.limitVar || 'first'] as number) || 100,
    limitVar = 'first',
    cursorVar = 'after',
  } = ctx.fetchAll as FetchAllOptions

  const subscribers: Array<Observer<FetchResult> | null> = []
  const pageSubscriptions: ObservableSubscription[] = []
  let subscriberCount = 0

  const combinedResult: FetchResult = {}
  const combinedEdges: RelayConnection['edges'] = []
  const combinedErrors: GraphQLError[] = []

  // Start cursor from first page and endCursor from last
  let startCursor: string | undefined
  let endCursor: string | undefined

  function propagateErrorToSubscriptions(err: Error) {
    pageSubscriptions.forEach(pageSubscription => {
      pageSubscription.unsubscribe()
    })
    subscribers.forEach(subscriber => {
      if (subscriber != null) {
        subscriber.error!(err)
      }
    })
  }

  function fetchNextPage(cursor: string | undefined) {
    const page = pageSubscriptions.length
    const nextOperation = createOperation(operation.getContext(), {
      ...operation,
      variables: {
        ...operation.variables,
        [limitVar]: perPage,
        [cursorVar]: cursor,
      },
    })

    pageSubscriptions[page] = forward(nextOperation).subscribe({
      next: res => {
        const hasErrors = res.errors?.length
        if (hasErrors) {
          combinedErrors.push(...res.errors!)
        }

        const pageConnection = getIn(res.data, path) as RelayConnection
        const pageInfo = pageConnection?.pageInfo

        console.log(page, 'page', pageInfo?.endCursor)

        // Throw if query result didn't include necessary pageInfo fields
        if (!(pageInfo && 'hasNextPage' in pageInfo && 'endCursor' in pageInfo) && !hasErrors) {
          propagateErrorToSubscriptions(new Error(
            `fetchAll: Expected path \`${path}\` to include \`pageInfo { hasNextPage endCursor }\` in selection`
          ))
          return
        }

        if (page === 0) {
          // Use result of first page as the basis for the combined result
          Object.assign(combinedResult, res)
          startCursor = pageInfo?.startCursor
        }

        if (pageConnection) {
          combinedEdges.push(...pageConnection.edges)
          endCursor = pageInfo.endCursor

          if (pageInfo.hasNextPage && pageInfo.endCursor) {
            // Continue fetching next page
            console.log(page, 'triggering fetchNextPage')
            fetchNextPage(pageInfo.endCursor)
            return
          }
        }

        // All pages fetched - combine them and notify observers
        console.log(page, 'no more pages')
        if (combinedEdges.length > 0) {
          updateIn(combinedResult.data, path, (existing) => ({
            ...existing,
            edges: combinedEdges,
            pageInfo: {
              ...existing?.pageInfo,
              startCursor,
              endCursor,
              hasNextPage: false,
              hasPreviousPage: false,
            }
          }))
        }

        if (combinedErrors.length > 0) {
          combinedResult.errors = combinedErrors
        }

        console.log(page, 'combined', combinedResult.data)
        subscribers.forEach(subscriber => {
          if (subscriber != null) {
            subscriber.next!(combinedResult)
            subscriber.complete!()
          }
        })
      },
      error: propagateErrorToSubscriptions,
    })
  }

  // Start recursive fetching from initial page cursor
  fetchNextPage(operation.variables?.[cursorVar])

  return new Observable(subscriber => {
    const subscriberIndex = subscribers.push(subscriber) - 1
    subscriberCount += 1
    return () => {
      // comment from apollo-link-retry source code:
      // Note that we are careful not to change the order or length of the array,
      // as we are often mid-iteration when calling this method.
      subscribers[subscriberIndex] = null
      subscriberCount -= 1
      if (subscriberCount === 0) {
        pageSubscriptions.forEach(pageSubscription => {
          pageSubscription.unsubscribe()
        })
      }
    }
  })
})

type PageInfo = {
  hasPreviousPage?: boolean
  hasNextPage?: boolean
  startCursor?: string
  endCursor?: string
}

type RelayConnection = {
  pageInfo: PageInfo
  edges: Array<{ node: any }>
}

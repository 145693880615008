export const defaultNumberFormat = {
  decimalSep: '.',
  thousandSep: ',',
  infinity: 'Infinity',
  nan: 'N/A',
}

/**
 * Formats a number according to a specified formatting string.
 *
 * Examples:
 * '0,000' => Add thousand separators in integer part of number, no decimals
 * '0.00' => Exactly two decimal places, no thousand separators
 * '0[.]00' => Zero or two decimal places, depending on in number is integer or not
 * '0 b' => Human readable byte sizes (K/M/G/T/etc., divide by 1024)
 * '0 a' => Abbreviate with K/M/G/etc (divide by 1000)
 * '+0' => Always show number sign
 *
 * More examples at: http://numeraljs.com/
 * (Ordinals, negative paretheses and time unsupported)
 *
 * Simplified and optimized version of formatNumber from Numeral.js:
 * https://github.com/adamwdraper/Numeral-js/blob/master/numeral.js
 *
 * @param input - Number to format.
 * @param [format="0,000[.]00"] - Formatting string.
 * @param numberFormat - Detailed format instructions.
 * @return Formatted string.
 */
export function formatNumber(
  input: unknown,
  format = '0,000[.]00',
  numberFormat = defaultNumberFormat,
): string {
  if (format == null) {
    throw new Error(`[formatNumber] Format not specified for value: ${input}`)
  }

  let neg = false // is negative
  let integer = '' // integer part
  let decimals = '' // decimal part
  let abbrev = '' // K/M/G/etc. abbreviations
  const signed = format.indexOf('+') >= 0
  const hasThousandSep = format.indexOf(',') >= 0
  const optionalDecimal = format.indexOf('[.]') >= 0

  if (input == null || input === '') {
    return ''
  }

  if (isFinite(input as number)) {
    let num = Number(input)
    // Abbreviations (a|b)
    const abbrevMap = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    const abbrevType =
      format.indexOf('a') >= 0 ? 'a' : format.indexOf('b') >= 0 ? 'b' : null
    if (abbrevType) {
      const k = abbrevType === 'b' ? 1024 : 1000
      const i = Math.floor(Math.log(Math.abs(num || 1)) / Math.log(k))
      num /= Math.pow(k, i)
      abbrev = [
        format.indexOf(' ' + abbrevType) >= 0 ? ' ' : '',
        abbrevMap[i],
        abbrevType === 'b' ? 'B' : '',
      ].join('')
    }

    // Remove handled tokens from format string
    format = format.replace('[.]', '.').replace(/[^0-9.]/g, '')

    integer = String(input).split('.')[0]
    const precision = format.split('.')[1]

    // Display requested number of decimals
    if (precision) {
      decimals = num.toFixed(precision.length)
      const dParts = decimals.split('.')
      integer = dParts[0]
      decimals = dParts[1].length ? numberFormat.decimalSep + dParts[1] : ''
      if (optionalDecimal && Number(decimals.slice(1)) === 0) {
        decimals = ''
      }
    } else {
      integer = num.toFixed(0)
    }

    // Negative?
    if (integer.indexOf('-') === 0) {
      integer = integer.slice(1)
      neg = true
    }

    if (format.indexOf('.') === 0) {
      integer = ''
    } else if (hasThousandSep) {
      integer = integer
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + numberFormat.thousandSep)
    }
  } else if (isNaN(input as any)) {
    integer = numberFormat.nan
  } else {
    // n is infinite
    neg = (input as any) < 0
    integer = numberFormat.infinity
  }

  return [neg ? '-' : signed ? '+' : '', integer, decimals, abbrev].join('')
}

import * as React from 'react'
import { cx } from '../lib/classNames'
import * as displayable from '../lib/displayable'
import { ElementProps } from '../lib/dom'
import { DisplayItem, DisplayItemProps } from './DisplayItem'

export function DisplayList<
  ItemType extends displayable.Displayable,
>({
  edges,
  format = 'list',
  columns = format === 'list' ? 1 : 3,
  allowDuplicates = false,
  itemProps,
  containerProps,
  skeletonUpTo,
  skeletonType,
}: {
  edges: Array<{ cursor?: string, node?: ItemType }> | null | undefined
  format?: DisplayItemProps<ItemType>['format']
  columns?: number
  allowDuplicates?: boolean
  containerProps?: ElementProps<HTMLDivElement>
  itemProps?: Partial<DisplayItemProps<ItemType>>
  skeletonUpTo?: number | false
  skeletonType?: displayable.DisplayableTypes
}) {
  if (skeletonUpTo && skeletonUpTo > (edges?.length || 0)) {
    edges = displayable.edgesWithSkeletons(skeletonUpTo, edges, skeletonType)
  } else if (!edges) {
    return null
  }

  return (
    <div
      role="list"
      {...containerProps}
      className={cx(
        `DisplayList DisplayList--${format} DisplayList--${columns}`,
        containerProps?.className,
      )}
    >
      {edges.map(({ node }, index) => {
        if (!node) { return null }
        return <DisplayItem
          key={allowDuplicates ? index : (node['id'] || index)}
          item={node}
          format={format}
          role="listitem"
          {...itemProps}
        />
      })}
    </div>
  )
}


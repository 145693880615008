import React from 'react'
import { useVirtual } from 'react-virtual'
import { documentElement, findScrollParent } from './dom'

export type UseVirtualScrollParentReturn = ReturnType<typeof useVirtual> & {
  estimateSizeRef: (element: Element) => void
}

export default function useVirtualScrollParent(options): UseVirtualScrollParentReturn {
  const sizeKey = options.horizontal ? 'width' : 'height'
  const { parentRef } = options
  const [rowSize, setRowSize] = React.useState(
    typeof options.estimateSize === 'function'
      ? options.estimateSize(0)
      : options.estimateSize || 0
  )

  // Mock the API surface currently used through parentRef
  const mockedParentRef = React.useRef(null)

  React.useLayoutEffect(() => {
    const scrolled = parentRef.current
    if (!scrolled) { return }

    let scrollParent: Partial<Element> = findScrollParent(scrolled)
    if (scrollParent === document.documentElement) {
      scrollParent = documentElement
    }

    let originalScrollListener = null
    const scrollListener = (originalEvent) => {
      // Compensate for potential <body> offset (due to disabled scrolling for example)
      const bodyOffset = scrollParent === documentElement
        ? parseInt(document.body.style.top || '0', 10)
        : 0
      const target = {
        scrollLeft: scrollParent.scrollLeft - scrolled.offsetLeft,
        scrollTop: scrollParent.scrollTop - scrolled.offsetTop - bodyOffset,
      }
      originalScrollListener({ target })
    }

    mockedParentRef.current = {
      get scrollLeft() {
        return scrollParent.scrollLeft - scrolled.offsetLeft
      },
      set scrollLeft(x) {
        scrollParent.scrollLeft = x + scrolled.offsetLeft
      },
      get scrollTop() {
        return scrollParent.scrollTop - scrolled.offsetTop
      },
      set scrollTop(x) {
        scrollParent.scrollTop = x + scrolled.offsetTop
      },
      getBoundingClientRect: () => ({
        width: scrollParent.clientWidth,
        height: scrollParent.clientHeight,
      }),
      addEventListener: (type, listener, ...args) => {
        // Only proxy 'scroll' event listeners
        if (type === 'scroll') {
          originalScrollListener = listener
          listener = scrollListener
          args = [] // has to be reset for IE11 to be able to later remove it
          listener()
        }
        return scrollParent.addEventListener(type, listener, ...args)
      },
      removeEventListener: (type, listener, ...args) => {
        if (type === 'scroll') {
          listener = scrollListener
          args = []
        }
        return scrollParent.removeEventListener(type, listener, ...args)
      }
    }
  }, [parentRef])

  const rowVirtualizer = useVirtual({
    ...options,
    parentRef: mockedParentRef,
    estimateSize: React.useCallback(() => rowSize, [rowSize]),
  })

  // Calculate row size from first item only
  const sizedElementRef = React.useRef<Element>()
  const estimateSizeRef = React.useCallback((element: Element) => {
    if (element && !sizedElementRef.current) {
      setRowSize(elementSize(element, sizeKey))
    }
    if (element) {
      // Avoid resetting to null as previous ref is unmounted before the next
      // one is mounted
      sizedElementRef.current = element
    }
  }, [setRowSize, sizeKey])

  // Re-calculate row size on window resize
  React.useLayoutEffect(() => {
    // TODO: Debounce with min=50 max=300 or similar
    const onResize = () => {
      if (!sizedElementRef.current) { return }
      // @ts-ignore
      setRowSize(elementSize(sizedElementRef.current, sizeKey))
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => { window.removeEventListener('resize', onResize) }
  }, [sizeKey, estimateSizeRef])

  return {
    ...rowVirtualizer,
    estimateSizeRef,
  }
}

/** Returns size (width/height) of the provided element, including margins. */
function elementSize(element: Element, dimension: 'width' | 'height'): number {
  if (!element) { return 0 }
  const style = window.getComputedStyle(element)
  let size = element.getBoundingClientRect()[dimension]
  switch (dimension) {
    case 'width':
      size += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10)
      break
    case 'height':
      size += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10)
      break
  }
  return size
}

import * as React from 'react'
import cx from 'clsx'
import composeRefs from '@seznam/compose-react-refs'

type ImageState = 'inactive' | 'loading' | 'loaded' | 'error'

export const FadedImage = React.forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement>
>((props, forwardedRef) => {
  const img = React.useRef<HTMLImageElement>()
  const [state, setState] = React.useState<ImageState>(props.src ? 'loading' : 'inactive')
  const timing = getTiming()
  const timingStarted = React.useRef(timing)

  React.useLayoutEffect(() => {
    timingStarted.current = getTiming()
    setState(img.current.complete ? 'loaded' : (props.src ? 'loading' : 'inactive'))
  }, [props.src])

  const onOutcome = (event) => {
    if (event.target.src === props.src) {
      setState(event.name === 'error' ? 'error' : 'loaded')
      props.onLoad?.(event)
    }
  }

  return React.createElement('img', {
    ...props,
    ref: composeRefs(forwardedRef, img),
    className: cx(
      `FadedImage FadedImage--${state}`,
      timing - timingStarted.current < 100 ? 'FadedImage--instant' : null,
      props.className,
    ),
    onLoad: onOutcome,
    onError: onOutcome,
  })
})

function getTiming(): number {
  return window.performance ? window.performance.now() : Date.now()
}

import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ArtistRouteDoc, IArtistRoute } from '~/src/graphql'
import { assertData } from '~/src/apollo/helpers'
import * as displayable from '~/src/lib/displayable'
import { DisplayItem } from '~/src/components/DisplayItem'
import { cx } from '../lib/classNames'
import { DisplayList } from '../components/DisplayList'
import DisplayImage from '../components/DisplayImage'

export default function Artist() {
  const params = useParams()
  const variables = {
    id: displayable.expandId(params.id, 'artist'),
    market: 'US' as any, // FIXME: Issue with enum validation
  }

  const query = useQuery(ArtistRouteDoc, {
    variables,
    notifyOnNetworkStatusChange: true, // re-render on fetchMore
    // fetchPolicy: 'cache-and-network', // revalidate on mount if cached
    returnPartialData: true,
  })

  let artist = assertData(query, 'artist')

  // Guard against empty partial data
  if (!artist?.__typename) { artist = null }

  const display = artist || displayable.skeletonOf('Artist')

  return <div key={display.id} className="Artist">
    <section className="toolbar PageHeader">
      <DisplayImage item={display} size="normal" className="PageHeader__image" />
      <div className="PageHeader__meta">
        <h2
          className={cx('PageHeader__title', !artist && 'placeholder-text')}
          children={display.name}
        />
      </div>
    </section>
    {artist?.station !== null && (
      <section>
        <h2>Artist playlist</h2>
        <DisplayItem
          item={artist?.station || displayable.skeletonOf('Playlist')}
        />
      </section>
    )}
    {artist?.tracks?.edges.length !== 0 && (
      <section id="tracks">
        <h2>Popular tracks</h2>
        <DisplayList
          edges={artist?.tracks?.edges}
          skeletonType="Track"
          skeletonUpTo={artist?.tracks ? 0 : 5}
        />
      </section>
    )}
    {artist?.albums?.edges.length !== 0 && (
      <ArtistAlbums albums={artist?.albums} />
    )}
  </div>
}

function ArtistAlbums({ albums }: {
  albums: IArtistRoute['artist']['albums'] | undefined
}) {
  const initialLimit = 5
  const [limit, setLimit] = React.useState(initialLimit)
  const length = albums?.edges.length || 0
  const edges = length > limit
    ? albums.edges.slice(0, limit)
    : albums?.edges
  return (
    <section id="albums">
      <div className="toolbar toolbar--baseline">
        <h2>Albums</h2>
        {length > initialLimit && (
          <a
            href="#albums"
            onClick={(event) => {
              event.preventDefault()
              setLimit(limit => (limit > initialLimit ? initialLimit : 1000))
              setTimeout(() => {
                const doc = document.documentElement
                const top = Math.max(
                  document.getElementById('albums')?.offsetTop || 0,
                  doc.scrollTop,
                )
                doc.scrollTo({ top, behavior: 'smooth' })
              })
            }}
            children={length > limit ? 'Show more' : 'Show less'}
          />
        )}
      </div>
      <DisplayList
        edges={edges}
        skeletonType="Album"
        skeletonUpTo={albums ? 0 : 3}
      />
    </section>
  )
}

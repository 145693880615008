import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import type { GraphQLError } from 'graphql'
import { useAuth } from '~/src/lib/auth'

ErrorView.displayName = 'ErrorView'

export default function ErrorView({
  error,
  onNavigate,
  safeMode = false,
}: {
  error: Error & {
    reactStack?: any,
    graphQLErrors?: GraphQLError[],
  },
  onNavigate?: () => void,
  safeMode?: boolean,
}) {
  const auth = useAuth({ throwWhenMissing: false })
  const onNavigateRef = React.useRef(onNavigate)
  onNavigateRef.current = onNavigate

  React.useEffect(() => {
    return auth?.subscribe((event) => {
      if (event === 'login') {
        onNavigateRef.current?.()
      }
    })
  }, [auth])

  const SafeLink = safeMode
    ? 'a'
    : ({ href, ...attrs }) => <Link to={href} {...attrs} />

  let context = error.stack?.replace(error.toString(), '')
  const graphQLStack = error.graphQLErrors?.[0]?.['graphQLStack']
  if (graphQLStack) {
    context = `GraphQL query: ${graphQLStack.join('.')}`
  }

  return (
    <section>
      {!!onNavigate && <NavigationHandler onNavigate={onNavigate} />}
      <h1>Error ocurred</h1>
      <pre children={error.message.trim()} />
      {!!context && <pre children={stripSources(context.trim())} />}
      {!!error.reactStack && <pre children={stripSources(error.reactStack.trim())} />}
      <ul className="flat">
        <li>
          <SafeLink href="/" children="Go to index" />
        </li>
        {auth?.loggedIn() ? (
          <li><a href="#" onClick={auth?.logout} children="Log out" /></li>
        ) : (
          <li><SafeLink href="/login" children="Log in" /></li>
        )}
      </ul>
    </section>
  )
}

type NavigationHandlerProps = {
  onNavigate: (location: ReturnType<typeof useLocation>) => void
}

const NavigationHandler = React.memo<NavigationHandlerProps>(({
  onNavigate,
}) => {
  const location = useLocation()
  const context = React.useRef<{
    onNavigate: NavigationHandlerProps['onNavigate'],
    location: typeof location,
  }>()
  const old = context.current?.location
  const changed = old &&
    (location.pathname !== old.pathname || location.search !== old.search)
  context.current = { onNavigate, location }

  React.useEffect(() => {
    if (changed) {
      context.current.onNavigate(context.current.location)
    }
  }, [changed])
  return null
})

function stripSources(str: string): string {
  const origin = location.origin.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regex = new RegExp(` \\((${origin})?/index\\.[a-z0-9]+\\.js[0-9:]+\\)`, 'g')
  return str.replace(regex, '')
}

import * as apollo from '@apollo/client'
import { getIn, Get } from '@soundtrackyourbrand/object-utils.js'

export function isLoading(
  query: apollo.QueryResult<any> | { data: any, loading: boolean, networkStatus?: number},
): boolean {
  if (!query.data) { return true }
  if (query.networkStatus == null) {
    return query.loading && !query.data
  }

  // https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
  switch (query.networkStatus) {
    case 1: // loading
      return !query.data
    case 2: // setVariables
    case 4: // refetch
      return true
    // case 5 is undefined
    case 3: // fetchMore
    case 6: // poll
    case 7: // ready
    case 8: // error
      return false
  }

  return false
}

export function assertData<Data, Path extends string>(
  /** Return value of `useQuery()` */
  query: { data: Data, error?: any, called?: boolean, loading?: boolean },
  /** Path to data as a dot delimeted string */
  path?: Path,
): Path extends string ? Get<Data, Path> : Data {
  const data = path ? getIn(query.data, path) : query.data

  if (data == null && !query.error && query.called && !query.loading) {
    // @ts-ignore
    query.error = Object.assign(new Error(`Data missing from response: '${path}'`), {
      status: 404,
    })
  }

  if (query.error) {
    throw query.error
  }

  return data as any
}

export function mapNodes(connection, mapFn) {
  return connection.edges.map(({ node }, ...args) => mapFn(node, ...args))
}

import * as React from 'react'
import { ISoundZoneFilter } from '~/src/graphql'
import SearchField from '~/src/components/SearchField'

export type ZoneStatus =
  | ''
  | 'online'
  | 'ok'
  | 'error'
  | 'paired'
  | 'unpaired'
  | 'inactive'
  | 'cancelled'
  | 'expired'
  | 'incomplete'

export function ZoneFilters({
  query,
  status,
  onUpdate,
}: {
  query?: string
  status?: ZoneStatus
  onUpdate: (field: string | null, value?: string) => void
}) {
  return (
    <SearchField
      query={query}
      onSearch={value => onUpdate('query', value)}
      debounce={500}
    >
      <select
        name="status"
        value={status || ''}
        onChange={event => onUpdate('status', event.target.value)}
      >
        <option value="">All</option>
        <option value="online">Online</option>
        <option value="error">Has errors</option>
        <option value="ok">OK</option>
        <option value="paired">Paired</option>
        <option value="unpaired">Unpaired</option>
        <option value="incomplete">Incomplete</option>
        <option value="inactive">Inactive</option>
        <option value="cancelled">Cancelled</option>
        <option value="expired">Expired</option>
      </select>
      <button
        type="button"
        onClick={() => onUpdate(null)}
        children="Clear"
      />
    </SearchField>
  )
}

export function generateFilters({ query: rawQuery, status }: {
  query?: string | null,
  status?: ZoneStatus,
}): ISoundZoneFilter | ISoundZoneFilter[] {
  const query = rawQuery
    ? { eq: '*' + rawQuery + '*' }
    : undefined

  switch (status) {
    case 'online':
      return [{
        query,
        online: true,
        isPaired: true,
        subscription: { eq: 'ACTIVE' },
      }]
    case 'ok':
    case 'error':
      return [{
        query,
        hasErrors: status !== 'ok',
        isPaired: true,
        subscription: { eq: 'ACTIVE' },
      }]
    case 'paired':
    case 'unpaired':
      return [{
        query,
        isPaired: status === 'paired',
      }]
    case 'inactive':
    case 'cancelled':
    case 'expired':
      return [{
        query,
        subscription: { eq: status.toUpperCase() as any }
      }]
    case 'incomplete':
      return [{
        query,
        subscription: { eq: 'INACTIVE' },
        isPaired: true,
      }, {
        query,
        subscription: { eq: 'EXPIRED' },
        isPaired: true,
      }, {
        query,
        subscription: { eq: 'ACTIVE' },
        isPaired: false,
      }, {
        query,
        subscription: { eq: 'CANCELLED' },
        isPaired: false,
      }]
  }

  return { query }
}

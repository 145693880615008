import * as React from 'react'
import ErrorView from './ErrorView'

type ErrorBoundaryProps = {
  resetOnNavigation?: boolean
  /** Render provided error instead of caught error */
  error?: Error
  reactStack?: any
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  static displayName = 'ErrorBoundary'

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: { error?: Error },
  ) {
    return {
      error: props.error || state.error,
    }
  }

  state = {
    error: this.props.error ? this.processError(
      this.props.error,
      this.props.reactStack,
    ) : null,
  }

  componentDidCatch(error, stackInfo) {
    this.setState({
      error: this.processError(error, stackInfo)
    })
  }

  processError(error, errorStack, force = false) {
    if (!error || typeof error !== 'object') {
      error = new Error('ErrorBoundary: ' + JSON.stringify(error))
    }
    error.reactStack = errorStack?.componentStack
    return error
  }

  reset = () => {
    if (this.state.error) {
      this.setState({ error: null })
    }
  }

  render() {
    const { error } = this.state
    return error
      ? <ErrorView
        onNavigate={this.props.resetOnNavigation && this.reset}
        error={error}
      />
      : this.props.children || null
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIn = void 0;
var updateIn_1 = require("./updateIn");
var initializer_1 = require("./initializer");
function setIn(obj, path, value, initializer) {
    if (initializer === void 0) { initializer = initializer_1.integerToArrayInitializer; }
    return (0, updateIn_1.updateIn)(obj, path, function () { return value; }, initializer);
}
exports.setIn = setIn;
